import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import SnackBarMsg from '@ifca-root/react-component/src/components/SnackBar/SnackBarMsg'
import Logo from 'assets/images/subscriptionx.png'
import Background from 'assets/images/HR-Background.jpg'
import { AuthLayout } from 'components/AuthLayout/AuthLayout'
import { useActivateUserMutation } from 'generated/graphql'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'

export const ActivatedUser = () => {
  const { token }: any = useParams()
  const [counter, setCounter] = useState(5)

  let history = useHistory()
  const [
    ActivatedUser,
    { loading: mutationUpdateLoading },
  ] = useActivateUserMutation({
    onError: error => {
      console.log('error', error)
    },
    onCompleted: data => {
      console.log('data', data)
      if (data?.activateUser && token) {
        setTimeout(() => {
          history.push(`/user/create-password/${token}`)
        }, 5000)
      } else {
        console.log('mutation return false')
      }
    },
  })

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 5000)
    return () => clearInterval(timer)
  }, [counter])
  useEffect(() => {
    if (token) {
      ActivatedUser({
        variables: {
          token: token,
        },
      })
    }
  }, [token])

  return (
    <AuthLayout logo={Logo} image={Background}>
      {mutationUpdateLoading && <Loading />}
      <div className="form-box">
        <span className="page-title">Your account has been created</span>
        {/* <div
          className="xsTitle m-t-4"
          style={{ color: 'white', textAlign: 'center' }}
        >
          Redirect to Create Password
        </div> */}
        {/* <Button
          type="submit"
          variant="contained"
          color="primary"
          className="login-btn"
          onClick={activatedUser}
        >
          Proceed to Create Password
        </Button> */}
      </div>
      <SnackBarMsg
        open={true}
        message={`  Redirect to create password page in ${counter} sec...`}
      />
    </AuthLayout>
  )
}
