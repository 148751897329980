import React, { lazy } from 'react'

const CurrencySetupListing = lazy(() =>
  import('./CurrencySetupListing').then(module => ({
    default: module.CurrencySetupListing,
  }))
)

const CurrencySetupRoutes = [
  {
    props: { exact: true, path: '/currency' },
    component: <CurrencySetupListing />,
  },
]

export default CurrencySetupRoutes
