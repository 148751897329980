import { ApolloProvider } from '@apollo/react-hooks';
import DateFnsUtils from '@date-io/date-fns';
import '@ifca-root/react-component/src/assets/styles/app.scss';
import 'assets/styles/subscriptionx.scss';
import theme from '@ifca-root/react-component/src/assets/theme';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import {
  Backdrop,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers/';
import { setAccessToken } from 'AccessToken';
import Layout from 'components/Layout/Layout';
import { createBrowserHistory } from 'history';
import {
  SubscriptionClient,
  SUBSCRIPTION_REFRESH_TOKEN_URL,
} from 'SubscriptionClient';
import React, { Suspense, useEffect, useReducer, useState } from 'react';
import { Router } from 'react-router-dom';
import Routes from './Router/Routes';
import AppContext from './Store/AppContext';
import { GlobalInitialState, RootReducer } from './Store/RootReducer';
import { useServiceWorker } from 'helpers/Hooks/useServiceWorker';

export const history = createBrowserHistory();

const App = () => {
  const [globalState, dispatch] = useReducer(RootReducer, GlobalInitialState);
  const [loading, setLoading] = useState(true);
  const { isUpdateAvailable, updateAssets } = useServiceWorker();
  const routing: any = history?.location?.pathname;

  const pathBeforeLogin = [
    '/authentication/404',
    '/reset-password',
    '/user/activate',
    '/user/create-password',
    '/login',
    '/forgot-password',
  ];

  const isNotMainContent = () => {
    return pathBeforeLogin?.filter(v => routing?.includes(v))?.length > 0;
  };

  useEffect(() => {
    fetch(SUBSCRIPTION_REFRESH_TOKEN_URL, {
      method: 'POST',
      credentials: 'include',
    }).then(async x => {
      const { accessToken } = await x?.json();
      setAccessToken(accessToken);
      setLoading(false);
    });
  }, []);

  // useEffect(() => {
  //   if (!!!key && !isNotMainContent()) {
  //     history.push('/login');
  //   }
  //   if (
  //     (user == null || Object?.keys(user)?.length == 0) &&
  //     !isNotMainContent()
  //   ) {
  //     history.push('/login');
  //   }
  // }, [key, user]);

  if (loading) {
    return <Loading />;
  }
  return (
    <AppContext.Provider value={{ globalState, dispatch }}>
      <ThemeProvider theme={theme}>
        <ApolloProvider client={SubscriptionClient}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Router history={history}>
              <Layout>
                <Suspense fallback={<Loading />}>
                  <Routes />
                  <Backdrop open={isUpdateAvailable}>
                    <Dialog
                      open={isUpdateAvailable}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title">
                        {'New Update Available'}
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          Please update SubscriptionX to the latest version to
                          continue.
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={updateAssets} autoFocus>
                          Update
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </Backdrop>
                </Suspense>
              </Layout>
            </Router>
          </MuiPickersUtilsProvider>
        </ApolloProvider>
      </ThemeProvider>
    </AppContext.Provider>
  );
};
export default App;
