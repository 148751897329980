import React, { lazy } from 'react'

const NotFound = lazy(() =>
  import('./NotFound').then(module => ({
    default: module.NotFound,
  }))
)

const Logout = lazy(() =>
  import('./Logout').then(module => ({
    default: module.Logout,
  }))
)

const Profile = lazy(() =>
  import('./Profile').then(module => ({
    default: module.Profile,
  }))
)

const EditProfile = lazy(() =>
  import('./EditProfile').then(module => ({
    default: module.EditProfile,
  }))
)

const ChangePassword = lazy(() =>
  import('./Password/ChangePassword').then(module => ({
    default: module.ChangePassword,
  }))
)

const TwoFASetup = lazy(() =>
  import('./TwoFASetup').then(module => ({
    default: module.TwoFASetup,
  }))
)

const authenticationRoutes = [
  {
    props: { path: '/logout' },
    component: <Logout />,
  },
  {
    props: { path: '/authentication/404' },
    component: <NotFound />,
  },
  {
    props: { path: '/profile/change-password' },
    component: <ChangePassword />,
  },
  {
    props: { path: '/profile' },
    component: <Profile />,
  },
  {
    props: { path: '/profile/edit' },
    component: <EditProfile />,
  },
  {
    props: { path: '/profile/setup-2fa' },
    component: <TwoFASetup />,
  },
]

export default authenticationRoutes
