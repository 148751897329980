import { yupResolver } from '@hookform/resolvers/yup'
import { StandardDialog } from '@ifca-root/react-component/src/components/Dialog/StandardDialog'
import SnackBarMsg from '@ifca-root/react-component/src/components/SnackBar/SnackBarMsg'
import {
  Button,
  IconButton,
  InputAdornment,
  TextField,
} from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import Background from 'assets/images/HR-Background.jpg'
import Logo from 'assets/images/subscriptionx.png'
import { AuthLayout } from 'components/AuthLayout/AuthLayout'
import { useSnackBar } from 'containers/helper/hooks/useSnackBar'
import { IAction } from 'containers/helper/models'
import { ResetPasswordSchema } from 'containers/helper/YupValidation'
import { useCreatePasswordMutation } from 'generated/graphql'
import { encryptMessage } from 'helpers/Crypto/JsEncrypt'
import JwtDecode from 'jwt-decode'
import React, { Reducer, useReducer } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useHistory, useParams } from 'react-router'

interface ResetProps {
  userName: string
  newPassword: string
  confirmPassword: string
}

interface resetPasswordProps {
  showNewPassword: boolean
  showConfirmPassword: boolean
  message: string
  dialog: boolean
}
export const CreatePassword = () => {
  let history = useHistory()
  const { token }: any = useParams()
  const { snackFunc, snackBarMessage, openSnackBar } = useSnackBar()

  const userInfo = JwtDecode(token) as any
  console.log(userInfo)
  const [createPassword, { loading: mutationLoading, error: mutationError }] =
    useCreatePasswordMutation({
      onError: error => {
        console.log('ERROR', error)
        snackFunc('Create Password Fail!', false)
      },
      onCompleted: data => {
        if (data.createPassword) {
          snackFunc('Create Password Successfully!', false)

          setTimeout(() => {
            history.push(`/login`)
          }, 1000)
        }
      },
    })

  const reducer: Reducer<resetPasswordProps, IAction> = (state, action) => {
    switch (action.type) {
      case 'reset':
        return initialState
      default:
        return { ...state, [action.type]: action.payload }
    }
  }
  const initialState: resetPasswordProps = {
    showNewPassword: false,
    showConfirmPassword: false,
    dialog: false,
    message: '',
  }

  const [state, dispatch] = useReducer(reducer, initialState)

  const { handleSubmit, register, errors, control, getValues } =
    useForm<ResetProps>({
      mode: 'onBlur',
      resolver: yupResolver(ResetPasswordSchema) as any,
    })

  const onSubmit = data => {
    createPassword({
      variables: {
        accountID: userInfo?.accountID,
        password: encryptMessage(data?.newPassword),
        confirmPassword: encryptMessage(data?.confirmPassword),
        userID: userInfo?.userID,
      },
    })
  }
  return (
    <>
      {/* {loading && <Loading />} */}
      <AuthLayout logo={Logo} image={Background}>
        <span className="page-title">Let's Get You Started!</span>
        <form onSubmit={handleSubmit(onSubmit)} id="submit-form">
          <div className="form-box">
            <Controller
              as={TextField}
              name="newPassword"
              label="New Password *"
              autoComplete="off"
              fullWidth
              ref={register}
              control={control}
              helperText={errors?.newPassword?.message}
              error={errors?.newPassword ? true : false}
              type={state.showNewPassword ? 'text' : 'password'}
              value={getValues('newPassword')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Password"
                      onClick={() =>
                        dispatch({
                          type: 'showNewPassword',
                          payload: !state.showNewPassword,
                        })
                      }
                    >
                      {state.showNewPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <Controller
              as={TextField}
              fullWidth
              helperText={errors?.confirmPassword?.message}
              error={errors?.confirmPassword ? true : false}
              autoComplete="off"
              name="confirmPassword"
              label="Confirm Password*"
              ref={register}
              control={control}
              type={state.showConfirmPassword ? 'text' : 'password'}
              value={getValues('confirmPassword')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Password"
                      onClick={() =>
                        dispatch({
                          type: 'showConfirmPassword',
                          payload: !state.showConfirmPassword,
                        })
                      }
                    >
                      {state.showConfirmPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>

          <Button
            variant="contained"
            color="primary"
            className="login-btn"
            type="submit"
            onClick={handleSubmit(onSubmit)}
          >
            Sign Up
          </Button>
        </form>

        <StandardDialog
          fullWidth={true}
          open={state.dialog}
          onClose={() =>
            dispatch({
              type: 'dialog',
              payload: false,
            })
          }
          sections={{
            header: {
              title: 'Erorr',
            },
            body: () => <div>{state.message}</div>,
            footer: {
              actions: [
                {
                  displayText: 'Ok',
                  props: {
                    onClick: () =>
                      dispatch({
                        type: 'dialog',
                        payload: false,
                      }),
                    variant: 'contained',
                    color: 'primary',
                  },
                },
              ],
            },
          }}
        />
        <SnackBarMsg open={openSnackBar} message={snackBarMessage} />
      </AuthLayout>
    </>
  )
}
