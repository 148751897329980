import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type AccountConfigEntity = AuditEntity & {
  __typename?: 'AccountConfigEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accessTokenDuration: Scalars['String'];
  refreshTokenDuration: Scalars['String'];
  accountID: Scalars['String'];
  pwdchangefrequency: Scalars['Float'];
  repeatPwd: Scalars['Float'];
};

export type AccountDeleteInput = {
  ID: Scalars['String'];
};

export type AccountEntity = AuditEntity & {
  __typename?: 'AccountEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  name: Scalars['String'];
  code: Scalars['String'];
  regNo?: Maybe<Scalars['String']>;
  contactNo: Scalars['String'];
  email: Scalars['String'];
  currencyID?: Maybe<Scalars['String']>;
  address?: Maybe<Address>;
  isInternal: Scalars['Boolean'];
  contactPerson?: Maybe<Array<ContactPerson>>;
  subscription: Array<SubscriptionEntity>;
  termsConditions: Array<TermsConditionsEntity>;
  bankAccount: Array<FromBankAccountEntity>;
  currency?: Maybe<CurrencyEntity>;
  softwareSubscription: SubscriptionEntity;
  latestSubscription: Scalars['JSON'];
};


export type AccountEntitySoftwareSubscriptionArgs = {
  softwareCode: Scalars['String'];
};


export type AccountEntityLatestSubscriptionArgs = {
  softwareCode: Scalars['String'];
};

export type AccountInput = {
  ID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  code: Scalars['String'];
  email: Scalars['String'];
  regNo: Scalars['String'];
  contactNo: Scalars['String'];
  softwareCode?: Maybe<Scalars['String']>;
  address?: Maybe<AddressInput>;
  contactPerson?: Maybe<Array<ContactPersonInput>>;
  currencyID?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  isInternal?: Maybe<Scalars['Boolean']>;
};

export enum AccountPackage {
  WorkDesk = 'WORK_DESK',
  GeneralLedger = 'GENERAL_LEDGER',
  CashBook = 'CASH_BOOK',
  AccountReceivable = 'ACCOUNT_RECEIVABLE',
  AccountPayable = 'ACCOUNT_PAYABLE',
  InventoryControl = 'INVENTORY_CONTROL',
  GeneralPurchases = 'GENERAL_PURCHASES'
}

/** JsonType */
export type Address = {
  __typename?: 'Address';
  siteName?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  postCodeName?: Maybe<Scalars['String']>;
  AddressType?: Maybe<AddressType>;
};

export type AddressInput = {
  siteName?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  postCodeName?: Maybe<Scalars['String']>;
  AddressType?: Maybe<AddressType>;
};

export enum AddressType {
  Primary = 'PRIMARY',
  Delivery = 'DELIVERY',
  Site = 'SITE'
}

export type AttachmentData = {
  __typename?: 'AttachmentData';
  content: Scalars['String'];
  filename: Scalars['String'];
};

export type AttachmentEntity = AuditEntity & {
  __typename?: 'AttachmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  mediaType: Scalars['String'];
  refTable: Scalars['String'];
  refID?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  fileURL?: Maybe<Scalars['String']>;
  accountID: Scalars['String'];
  bucketFileName?: Maybe<Scalars['String']>;
  opacity?: Maybe<Scalars['String']>;
  isPrivate: Scalars['Boolean'];
  folderExist: Scalars['Boolean'];
  commonStatus: CommonStatus;
  account: AccountEntity;
};

export type AttachmentInput = {
  refTable: Scalars['String'];
  refID: Scalars['String'];
  file: Array<Scalars['Upload']>;
};

export type AuditEntity = {
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
};

export type AzureStorageOutput = {
  __typename?: 'AzureStorageOutput';
  BucketFileName: Scalars['String'];
  FileName: Scalars['String'];
  Url: Scalars['String'];
  MediaType: Scalars['String'];
  IsPrivate?: Maybe<Scalars['Boolean']>;
  PrivateUrl?: Maybe<Scalars['String']>;
};

export type BaseInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};

export type CityEntity = AuditEntity & {
  __typename?: 'CityEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  name: Scalars['String'];
  stateID: Scalars['String'];
  state: StateEntity;
  commonStatus: CommonStatus;
  district: Array<DistrictEntity>;
  postcode: Array<PostcodeEntity>;
};

export type CityInput = {
  name?: Maybe<Scalars['String']>;
  stateID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
};

export enum CommonStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Block = 'BLOCK'
}

/** JsonType */
export type ContactPerson = {
  __typename?: 'ContactPerson';
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  associateID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  designation?: Maybe<Scalars['String']>;
  contactNo?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type ContactPersonInput = {
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  associateID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  designation?: Maybe<Scalars['String']>;
  contactNo?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export enum ContractPackage {
  WorkDesk = 'WORK_DESK',
  BillOfQuantities = 'BILL_OF_QUANTITIES',
  WorkBreakdownStructure = 'WORK_BREAKDOWN_STRUCTURE',
  ContractManagement = 'CONTRACT_MANAGEMENT',
  ClientAccount = 'CLIENT_ACCOUNT',
  DefectManagement = 'DEFECT_MANAGEMENT',
  PlantMachinery = 'PLANT_MACHINERY',
  ProjectPurchase = 'PROJECT_PURCHASE',
  ProjectCosting = 'PROJECT_COSTING',
  SubconTender = 'SUBCON_TENDER',
  SubcontractManagement = 'SUBCONTRACT_MANAGEMENT',
  SubcontractorAccount = 'SUBCONTRACTOR_ACCOUNT',
  SupplierAccount = 'SUPPLIER_ACCOUNT',
  SystemAdmin = 'SYSTEM_ADMIN',
  SiteAttendance = 'SITE_ATTENDANCE',
  SiteInventory = 'SITE_INVENTORY',
  SiteSurveillance = 'SITE_SURVEILLANCE',
  SiteWages = 'SITE_WAGES'
}

export type CoreBaseEntity = AuditEntity & {
  __typename?: 'CoreBaseEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
};

export type CountryEntity = AuditEntity & {
  __typename?: 'CountryEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  name: Scalars['String'];
  code: Scalars['String'];
  commonStatus: CommonStatus;
  state: Array<StateEntity>;
};

export type CountryInput = {
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
};

export type CreateAuditInput = {
  createdBy?: Maybe<Scalars['String']>;
};

export type CurrencyDeleteInput = {
  ID: Scalars['String'];
};

export type CurrencyEntity = AuditEntity & {
  __typename?: 'CurrencyEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  code: Scalars['String'];
  name: Scalars['String'];
  symbol: Scalars['String'];
  commonStatus: CommonStatus;
  account?: Maybe<Array<AccountEntity>>;
};

export type CurrencyExchangeEntity = AuditEntity & {
  __typename?: 'CurrencyExchangeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  fromCurrencyID: Scalars['String'];
  toCurrencyID: Scalars['String'];
  effectiveDate: Scalars['DateTime'];
  fromAmt: Scalars['Float'];
  toAmt: Scalars['Float'];
  exchangeRate: Scalars['Float'];
  commonStatus: CommonStatus;
};

export type CurrencyInput = {
  ID?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  symbol?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};


export type DistrictEntity = AuditEntity & {
  __typename?: 'DistrictEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  name: Scalars['String'];
  cityID: Scalars['String'];
  city: CityEntity;
  commonStatus: CommonStatus;
};

export type DistrictInput = {
  name?: Maybe<Scalars['String']>;
  cityID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
};

export type DownloadLogEntity = AuditEntity & {
  __typename?: 'DownloadLogEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  softwareName: Scalars['String'];
  attachmentID?: Maybe<Scalars['String']>;
  fileName: Scalars['String'];
  fileSize: Scalars['Float'];
  modulePath?: Maybe<Scalars['String']>;
  ipAddress?: Maybe<Scalars['String']>;
  commonStatus: CommonStatus;
};

export type DownloadLogInput = {
  accountID: Scalars['String'];
  softwareName: Scalars['String'];
  attachmentID?: Maybe<Scalars['String']>;
  fileName: Scalars['String'];
  fileSize: Scalars['Float'];
  modulePath?: Maybe<Scalars['String']>;
  ipAddress?: Maybe<Scalars['String']>;
};

export type EmailBlastArgs = {
  __typename?: 'EmailBlastArgs';
  senderName: Scalars['String'];
  senderEmail: Scalars['String'];
  recipients: Array<Scalars['String']>;
  subject: Scalars['String'];
  message: Scalars['String'];
  isMultiple: Scalars['Boolean'];
  ccEmail?: Maybe<Array<Scalars['String']>>;
  bccEmail?: Maybe<Array<Scalars['String']>>;
  categories?: Maybe<Array<Scalars['String']>>;
  attachments?: Maybe<Array<AttachmentData>>;
  replyTo?: Maybe<Scalars['String']>;
  sentAt?: Maybe<Scalars['Float']>;
};

export type EmailBlastAttachment = {
  content: Scalars['String'];
  filename: Scalars['String'];
};

export type EmailBlastInput = {
  senderName: Scalars['String'];
  recipients: Array<Scalars['String']>;
  subject: Scalars['String'];
  message: Scalars['String'];
  isMultiple: Scalars['Boolean'];
  ccEmail?: Maybe<Array<Scalars['String']>>;
  bccEmail?: Maybe<Array<Scalars['String']>>;
  attachments?: Maybe<Array<EmailBlastAttachment>>;
  categories?: Maybe<Array<Scalars['String']>>;
  replyTo?: Maybe<Scalars['String']>;
  sendAtUnix?: Maybe<Scalars['Float']>;
};

export type EmailBlastLogEntity = AuditEntity & {
  __typename?: 'EmailBlastLogEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  commonStatus: Scalars['String'];
  accountId: Scalars['String'];
  softwareId: Scalars['String'];
  softwareCode: Scalars['String'];
  args?: Maybe<EmailBlastArgs>;
  recipientCount: Scalars['Float'];
  errorMsg?: Maybe<Scalars['String']>;
};

/** JsonType */
export type EntityControl = {
  __typename?: 'EntityControl';
  noOfEntity?: Maybe<Scalars['Float']>;
  noofExtraEntity?: Maybe<Scalars['Float']>;
};

export type EntityControlInput = {
  noOfEntity?: Maybe<Scalars['Float']>;
  noofExtraEntity?: Maybe<Scalars['Float']>;
};

export type FieldError = {
  __typename?: 'FieldError';
  field: Scalars['String'];
  message: Scalars['String'];
};

export type FromBankAccountEntity = AuditEntity & {
  __typename?: 'FromBankAccountEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
  accountHolder: Scalars['String'];
  accountNo: Scalars['String'];
  branch: Scalars['String'];
  address: Address;
  account: AccountEntity;
};

export type FromBankAccountInput = {
  accountHolder?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  branch?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
};

export type FromBankDetails = {
  __typename?: 'FromBankDetails';
  name?: Maybe<Scalars['String']>;
  acctHolder?: Maybe<Scalars['String']>;
  acctNo?: Maybe<Scalars['String']>;
  branch?: Maybe<Scalars['String']>;
};

export type FromBankDetailsInput = {
  name?: Maybe<Scalars['String']>;
  acctHolder?: Maybe<Scalars['String']>;
  acctNo?: Maybe<Scalars['String']>;
  branch?: Maybe<Scalars['String']>;
};

export enum HrPackage {
  Platformadmin = 'PLATFORMADMIN',
  Systemadmin = 'SYSTEMADMIN',
  Payroll = 'PAYROLL',
  Personnel = 'PERSONNEL',
  Employeeengagement = 'EMPLOYEEENGAGEMENT',
  Businessinsight = 'BUSINESSINSIGHT',
  Leavemanagement = 'LEAVEMANAGEMENT',
  Claimmanagement = 'CLAIMMANAGEMENT',
  Requisitionmanagement = 'REQUISITIONMANAGEMENT',
  Timeattendance = 'TIMEATTENDANCE',
  Reporting = 'REPORTING',
  Staffappraisal = 'STAFFAPPRAISAL',
  Trainingdevelopment = 'TRAININGDEVELOPMENT',
  Timesheet = 'TIMESHEET',
  Projectexpensemanagement = 'PROJECTEXPENSEMANAGEMENT'
}

export enum HotelPackage {
  Platformadmin = 'PLATFORMADMIN',
  Pms = 'PMS',
  Cityledger = 'CITYLEDGER',
  Microsite = 'MICROSITE',
  Hotelplatform = 'HOTELPLATFORM',
  Guestapp = 'GUESTAPP',
  Travelagent = 'TRAVELAGENT',
  Corporategovernment = 'CORPORATEGOVERNMENT'
}

export type HuaweiStorageOutput = {
  __typename?: 'HuaweiStorageOutput';
  BucketFileName: Scalars['String'];
  FileName: Scalars['String'];
  Url: Scalars['String'];
  MediaType: Scalars['String'];
  IsPrivate?: Maybe<Scalars['Boolean']>;
  PrivateUrl?: Maybe<Scalars['String']>;
};

export enum IfcaPackage {
  Marketing = 'MARKETING',
  Sales = 'SALES',
  Contract = 'CONTRACT',
  Issue = 'ISSUE'
}


export type LoginResponse = {
  __typename?: 'LoginResponse';
  accessToken: Scalars['String'];
  user: UserResponse;
};

export enum MenuPackages {
  Outlet = 'OUTLET',
  Patron = 'PATRON',
  Kitchen = 'KITCHEN'
}

export type Mutation = {
  __typename?: 'Mutation';
  UploadAttachmentProduct: AzureStorageOutput;
  uploadAttachments: Scalars['Boolean'];
  deleteAttachment: Scalars['Boolean'];
  createCurrency: CurrencyEntity;
  updateCurrency: CurrencyEntity;
  deleteCurrency: Scalars['Boolean'];
  patchJohorDistrict: Scalars['JSON'];
  patchMissingCities: Scalars['JSON'];
  patchStateDistrict: Scalars['JSON'];
  createCountry: CountryEntity;
  updateCountry: CountryEntity;
  deleteCountry: Scalars['Boolean'];
  createState: StateEntity;
  updateState: StateEntity;
  deleteState: Scalars['Boolean'];
  createCity: CityEntity;
  updateCity: CityEntity;
  deleteCity: Scalars['Boolean'];
  createDistrict: DistrictEntity;
  updateDistrict: DistrictEntity;
  deleteDistrict: Scalars['Boolean'];
  createPostcode: PostcodeEntity;
  updatePostcode: PostcodeEntity;
  deletePostcode: Scalars['Boolean'];
  deleteAccount: Scalars['Boolean'];
  createAccount: AccountEntity;
  updateAccount: AccountEntity;
  createFromBankAccount: FromBankAccountEntity;
  updateFromBankAccount: FromBankAccountEntity;
  deleteFromBankAccount: Scalars['Boolean'];
  createDownloadLog: Scalars['Boolean'];
  emailBlast: Scalars['Boolean'];
  updatePackage: PackageEntity;
  deletePackage: Scalars['Boolean'];
  createReportLog: Scalars['Boolean'];
  updateSoftware: SoftwareEntity;
  deleteSoftware: Scalars['Boolean'];
  createSubscription: SubscriptionEntity;
  updateSubscription: Scalars['Boolean'];
  createTermsConditions: TermsConditionsEntity;
  updateTermsConditions: TermsConditionsEntity;
  createUser: UserEntity;
  createPassword: Scalars['Boolean'];
  updateUser: UserEntity;
  activateUser: Scalars['Boolean'];
  login: LoginResponse;
  changePassword: Scalars['Boolean'];
  logout: Scalars['Boolean'];
  forgotPassword: Scalars['Boolean'];
  resetPassword: Scalars['Boolean'];
  TwoFATotpSecretToggle: Scalars['Boolean'];
  totpValidate: Scalars['Boolean'];
  createUserActionLog: UserActionLogEntity;
  createOnlineUserLog: UserActionLogEntity;
  deleteOnlinUserLog: Scalars['Boolean'];
  logUserOnline: Scalars['Boolean'];
  logUserOffline: Scalars['Boolean'];
  logUserOfflineWithoutSocketId: Scalars['Boolean'];
  TestBlast: Scalars['Boolean'];
};


export type MutationUploadAttachmentProductArgs = {
  isPrivate?: Maybe<Scalars['Boolean']>;
  file: Scalars['Upload'];
};


export type MutationUploadAttachmentsArgs = {
  input: AttachmentInput;
};


export type MutationDeleteAttachmentArgs = {
  refID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
};


export type MutationCreateCurrencyArgs = {
  input: CurrencyInput;
};


export type MutationUpdateCurrencyArgs = {
  input: CurrencyInput;
};


export type MutationDeleteCurrencyArgs = {
  input: CurrencyDeleteInput;
};


export type MutationPatchStateDistrictArgs = {
  state: Scalars['String'];
};


export type MutationCreateCountryArgs = {
  input: CountryInput;
};


export type MutationUpdateCountryArgs = {
  input: CountryInput;
};


export type MutationDeleteCountryArgs = {
  input: CountryInput;
};


export type MutationCreateStateArgs = {
  input: StateInput;
};


export type MutationUpdateStateArgs = {
  input: StateInput;
};


export type MutationDeleteStateArgs = {
  input: StateInput;
};


export type MutationCreateCityArgs = {
  input: CityInput;
};


export type MutationUpdateCityArgs = {
  input: CityInput;
};


export type MutationDeleteCityArgs = {
  input: CityInput;
};


export type MutationCreateDistrictArgs = {
  input: DistrictInput;
};


export type MutationUpdateDistrictArgs = {
  input: DistrictInput;
};


export type MutationDeleteDistrictArgs = {
  input: DistrictInput;
};


export type MutationCreatePostcodeArgs = {
  input: PostcodeInput;
};


export type MutationUpdatePostcodeArgs = {
  input: PostcodeInput;
};


export type MutationDeletePostcodeArgs = {
  input: PostcodeInput;
};


export type MutationDeleteAccountArgs = {
  input: AccountDeleteInput;
};


export type MutationCreateAccountArgs = {
  input: AccountInput;
};


export type MutationUpdateAccountArgs = {
  input: AccountInput;
};


export type MutationCreateFromBankAccountArgs = {
  input: FromBankAccountInput;
};


export type MutationUpdateFromBankAccountArgs = {
  input: FromBankAccountInput;
};


export type MutationDeleteFromBankAccountArgs = {
  input: FromBankAccountInput;
};


export type MutationCreateDownloadLogArgs = {
  input: DownloadLogInput;
};


export type MutationEmailBlastArgs = {
  userID: Scalars['String'];
  softwareCode: Scalars['String'];
  accountID: Scalars['String'];
  input: EmailBlastInput;
};


export type MutationUpdatePackageArgs = {
  input: PackageInput;
};


export type MutationDeletePackageArgs = {
  input: PackageDeleteInput;
};


export type MutationCreateReportLogArgs = {
  input: ReportLogInput;
};


export type MutationUpdateSoftwareArgs = {
  input: SoftwareInput;
};


export type MutationDeleteSoftwareArgs = {
  input: SoftwareDelInput;
};


export type MutationCreateSubscriptionArgs = {
  input: SubscriptionInput;
};


export type MutationUpdateSubscriptionArgs = {
  input: SubscriptionUpdateInput;
};


export type MutationCreateTermsConditionsArgs = {
  input: TermsConditionsInput;
};


export type MutationUpdateTermsConditionsArgs = {
  input: TermsConditionsInput;
};


export type MutationCreateUserArgs = {
  input: UserInput;
};


export type MutationCreatePasswordArgs = {
  accountID: Scalars['String'];
  userID: Scalars['String'];
  confirmPassword: Scalars['String'];
  password: Scalars['String'];
};


export type MutationUpdateUserArgs = {
  input: UserInput;
};


export type MutationActivateUserArgs = {
  token: Scalars['String'];
};


export type MutationLoginArgs = {
  password: Scalars['String'];
  loginId: Scalars['String'];
};


export type MutationChangePasswordArgs = {
  newPassword: Scalars['String'];
  currentPassword: Scalars['String'];
};


export type MutationForgotPasswordArgs = {
  email: Scalars['String'];
};


export type MutationResetPasswordArgs = {
  accountID?: Maybe<Scalars['String']>;
  userID?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  token: Scalars['String'];
};


export type MutationTwoFaTotpSecretToggleArgs = {
  is2FAEnable: Scalars['Boolean'];
};


export type MutationTotpValidateArgs = {
  token: Scalars['String'];
};


export type MutationCreateUserActionLogArgs = {
  input: UserActionLogInput;
};


export type MutationCreateOnlineUserLogArgs = {
  input: OnlineUserInput;
};


export type MutationDeleteOnlinUserLogArgs = {
  accountID: Scalars['String'];
  userID: Scalars['String'];
};


export type MutationLogUserOnlineArgs = {
  ipAddress?: Maybe<Scalars['String']>;
  packageName?: Maybe<Scalars['String']>;
  entityID?: Maybe<Scalars['String']>;
  socketID: Scalars['String'];
  accountID: Scalars['String'];
  softwareName: Scalars['String'];
  userID: Scalars['String'];
};


export type MutationLogUserOfflineArgs = {
  packageName?: Maybe<Scalars['String']>;
  entityID?: Maybe<Scalars['String']>;
  socketID: Scalars['String'];
  accountID: Scalars['String'];
  softwareName: Scalars['String'];
  userID: Scalars['String'];
};


export type MutationLogUserOfflineWithoutSocketIdArgs = {
  packageName?: Maybe<Scalars['String']>;
  entityID?: Maybe<Scalars['String']>;
  accountID: Scalars['String'];
  softwareName: Scalars['String'];
  userID: Scalars['String'];
};


export type MutationTestBlastArgs = {
  accountID: Scalars['String'];
  userID: Scalars['String'];
};

export type OnlineUserEntity = AuditEntity & {
  __typename?: 'OnlineUserEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  softwareID?: Maybe<Scalars['String']>;
  softwareName?: Maybe<Scalars['String']>;
  packageName?: Maybe<Scalars['String']>;
  entityName?: Maybe<Scalars['String']>;
  userID: Scalars['String'];
  userName?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  entityID?: Maybe<Scalars['String']>;
  ipAddress?: Maybe<Scalars['String']>;
  loginDateTime?: Maybe<Scalars['DateTime']>;
  commonStatus: Scalars['String'];
  lastLogin: Scalars['DateTime'];
  lastLogout?: Maybe<Scalars['DateTime']>;
  socketID?: Maybe<Scalars['String']>;
};

export type OnlineUserInput = {
  softwareID?: Maybe<Scalars['String']>;
  softwareName?: Maybe<Scalars['String']>;
  packageName?: Maybe<Scalars['String']>;
  entityName?: Maybe<Scalars['String']>;
  userID: Scalars['String'];
  userName?: Maybe<Scalars['String']>;
  accountID: Scalars['String'];
  ipAddress: Scalars['String'];
};

export type PackageDeleteInput = {
  ID: Scalars['String'];
};

export type PackageEntity = AuditEntity & {
  __typename?: 'PackageEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  name: Scalars['String'];
  softwareID: Scalars['String'];
  software: SoftwareEntity;
};

export type PackageInput = {
  ID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  softwareID?: Maybe<Scalars['String']>;
};

export type PostcodeEntity = AuditEntity & {
  __typename?: 'PostcodeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  postcode: Scalars['String'];
  cityID: Scalars['String'];
  city: CityEntity;
  commonStatus: CommonStatus;
};

export type PostcodeInput = {
  postcode?: Maybe<Scalars['String']>;
  cityID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
};

export enum PropertyPackages {
  AgencyPlatform = 'AGENCY_PLATFORM',
  LoanPlatform = 'LOAN_PLATFORM',
  SalesPlatform = 'SALES_PLATFORM',
  SolicitorPlatform = 'SOLICITOR_PLATFORM',
  PurchaserApp = 'PURCHASER_APP',
  ELaunch = 'E_LAUNCH',
  SalesAdmin = 'SALES_ADMIN'
}

export type Query = {
  __typename?: 'Query';
  DeleteAttachmentProduct: Scalars['Boolean'];
  DeleteAttachmentProducts: Scalars['Boolean'];
  AttachmentListing?: Maybe<Array<AttachmentEntity>>;
  getCurrency: Array<CurrencyEntity>;
  getCountry: Array<CountryEntity>;
  getState: Array<StateEntity>;
  getStatebyCountry: Array<StateEntity>;
  getCitybyState: Array<CityEntity>;
  getDistrictbyCity: Array<DistrictEntity>;
  getPostcodebyCity: Array<PostcodeEntity>;
  getAccount: Array<AccountEntity>;
  accountConfig: AccountConfigEntity;
  getAccountDetail: AccountEntity;
  getAccountsBySoftwareCode: Array<AccountEntity>;
  getFromBankAccount: Array<FromBankAccountEntity>;
  getPackageByAccount: Array<Scalars['String']>;
  getPackageSoftwareCode: Array<Scalars['String']>;
  declareSoftwareCodeEnum: Scalars['String'];
  declareSoftwarePackagesEnum: Scalars['JSON'];
  getSubscription: Array<SubscriptionEntity>;
  testPath: Scalars['JSON'];
  subscribedUserAndEntityCount: SubscriptionEntity;
  getTermsConditions: Array<TermsConditionsEntity>;
  loggedInUserProfile: UserEntity;
  activeUser: Scalars['Float'];
  getUsersByAccount: Array<UserEntity>;
  getUser: UserEntity;
  getUsersbyIds: Array<UserEntity>;
  unLockUser: Scalars['Boolean'];
  isLoginPermitted: UserEntity;
  isUserValid: Scalars['Boolean'];
  getUsersBySoftware: Array<UserEntity>;
  getSuperUsers: Array<UserEntity>;
  TwoFATotpDetails: TotpSecretTyes;
};


export type QueryDeleteAttachmentProductArgs = {
  isPrivate?: Maybe<Scalars['Boolean']>;
  filename: Scalars['String'];
};


export type QueryDeleteAttachmentProductsArgs = {
  isPrivate?: Maybe<Scalars['Boolean']>;
  filenames: Array<Scalars['String']>;
};


export type QueryAttachmentListingArgs = {
  refTable?: Maybe<Scalars['String']>;
  refID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetCurrencyArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  symbol?: Maybe<Scalars['String']>;
};


export type QueryGetCountryArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
};


export type QueryGetStateArgs = {
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  countryID?: Maybe<Scalars['String']>;
};


export type QueryGetStatebyCountryArgs = {
  countryID: Scalars['String'];
};


export type QueryGetCitybyStateArgs = {
  stateID: Scalars['String'];
};


export type QueryGetDistrictbyCityArgs = {
  cityID: Scalars['String'];
};


export type QueryGetPostcodebyCityArgs = {
  cityID: Scalars['String'];
};


export type QueryGetAccountArgs = {
  ID?: Maybe<Scalars['String']>;
};


export type QueryAccountConfigArgs = {
  accountID: Scalars['String'];
};


export type QueryGetAccountDetailArgs = {
  accountID: Scalars['String'];
};


export type QueryGetAccountsBySoftwareCodeArgs = {
  softwareCode: Scalars['String'];
};


export type QueryGetFromBankAccountArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetPackageByAccountArgs = {
  softwareCode: Scalars['String'];
  accountID: Scalars['String'];
};


export type QueryGetPackageSoftwareCodeArgs = {
  softwareCode: Scalars['String'];
};


export type QueryDeclareSoftwareCodeEnumArgs = {
  SoftwareCode: SoftwareCode;
};


export type QueryDeclareSoftwarePackagesEnumArgs = {
  AccountPackage: AccountPackage;
  ReportDesignerPackage: ReportDesignerPackage;
  PropertyPackages: PropertyPackages;
  MenuPackages: MenuPackages;
  IfcaPackage: IfcaPackage;
  HRPackage: HrPackage;
  HotelPackage: HotelPackage;
  ContractPackage: ContractPackage;
};


export type QueryGetSubscriptionArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};


export type QuerySubscribedUserAndEntityCountArgs = {
  softwareCode?: Maybe<SoftwareCode>;
  accountID: Scalars['String'];
};


export type QueryGetTermsConditionsArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};


export type QueryGetUsersByAccountArgs = {
  status?: Maybe<CommonStatus>;
  superUser?: Maybe<Scalars['Boolean']>;
  LatetestUser?: Maybe<Scalars['Boolean']>;
  accountID: Scalars['String'];
};


export type QueryGetUserArgs = {
  ID: Scalars['String'];
};


export type QueryGetUsersbyIdsArgs = {
  userIDs: Array<Scalars['String']>;
};


export type QueryUnLockUserArgs = {
  password: Scalars['String'];
  accountID: Scalars['String'];
  userIDs: Scalars['String'];
};


export type QueryIsLoginPermittedArgs = {
  deviceIP: Scalars['String'];
};


export type QueryIsUserValidArgs = {
  userID: Scalars['String'];
};

export type Recipient = {
  __typename?: 'Recipient';
  name?: Maybe<Scalars['String']>;
  email: Scalars['String'];
};

export enum ReportDesignerPackage {
  Contract = 'CONTRACT',
  Hr = 'HR',
  Property = 'PROPERTY',
  Hotel = 'HOTEL',
  Menu = 'MENU',
  Account = 'ACCOUNT',
  Ifca = 'IFCA',
  Subscription = 'SUBSCRIPTION'
}

export type ReportLogEntity = {
  __typename?: 'ReportLogEntity';
  ID: Scalars['String'];
  accountID?: Maybe<Scalars['String']>;
  accountName?: Maybe<Scalars['String']>;
  softwareID?: Maybe<Scalars['String']>;
  softwareName?: Maybe<Scalars['String']>;
  userID?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
  reportName?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  requestDate?: Maybe<Scalars['DateTime']>;
};

export type ReportLogInput = {
  accountID?: Maybe<Scalars['String']>;
  accountName?: Maybe<Scalars['String']>;
  softwareID?: Maybe<Scalars['String']>;
  softwareName?: Maybe<Scalars['String']>;
  userID?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
  reportName?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
};

export enum SoftwareCode {
  Contract = 'CONTRACT',
  Site = 'SITE',
  Client = 'CLIENT',
  Subcontractor = 'SUBCONTRACTOR',
  Supplier = 'SUPPLIER',
  Hr = 'HR',
  Property = 'PROPERTY',
  Rent = 'RENT',
  Hotel = 'HOTEL',
  Account = 'ACCOUNT',
  Ifca = 'IFCA',
  Subscription = 'SUBSCRIPTION',
  User = 'USER',
  Tenant = 'TENANT',
  LeaseTodo = 'LEASE_TODO',
  LeaseOperation = 'LEASE_OPERATION',
  LeaseSales = 'LEASE_SALES',
  Menu = 'MENU',
  PosCustomer = 'POS_CUSTOMER',
  Probation = 'PROBATION',
  ReportDesigner = 'REPORT_DESIGNER'
}

export type SoftwareDelInput = {
  ID: Scalars['String'];
};

export type SoftwareEntity = AuditEntity & {
  __typename?: 'SoftwareEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  name: Scalars['String'];
  code: Scalars['String'];
  subscriptionID: Scalars['String'];
  commonStatus: CommonStatus;
  subscription: SubscriptionEntity;
  package: Array<PackageEntity>;
};

export type SoftwareInput = {
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
};

export type SoftwareUpdateInput = {
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
};

export type StateEntity = AuditEntity & {
  __typename?: 'StateEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  name: Scalars['String'];
  countryID: Scalars['String'];
  country: CountryEntity;
  commonStatus: CommonStatus;
  city: Array<CityEntity>;
};

export type StateInput = {
  name?: Maybe<Scalars['String']>;
  countryID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
};

export type SubscriptionEntity = AuditEntity & {
  __typename?: 'SubscriptionEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  subscriptionAmt: Scalars['Float'];
  salesOrderNo: Scalars['String'];
  remark: Scalars['String'];
  terminationReason?: Maybe<Scalars['String']>;
  isTerminated: Scalars['Boolean'];
  saleOrderDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  implementationDate?: Maybe<Scalars['DateTime']>;
  noOfUser: Scalars['Float'];
  is2FA: Scalars['Boolean'];
  entityControl?: Maybe<EntityControl>;
  commonStatus: CommonStatus;
  /** CustomFieldResolver */
  daysToGo: Scalars['Float'];
  account: AccountEntity;
  software: Array<SoftwareEntity>;
};

export type SubscriptionInput = {
  accountID: Scalars['String'];
  softwareCode: Scalars['String'];
  softwareName: Scalars['String'];
  subscriptionAmt?: Maybe<Scalars['Float']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  implementationDate: Scalars['DateTime'];
  noOfUser: Scalars['Float'];
  saleOrderDate?: Maybe<Scalars['DateTime']>;
  salesOrderNo?: Maybe<Scalars['String']>;
  remark: Scalars['String'];
  entityControl: EntityControlInput;
  packages: Array<Scalars['String']>;
  is2FA?: Maybe<Scalars['Boolean']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type SubscriptionUpdateInput = {
  ID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  softwareCode?: Maybe<Scalars['String']>;
  softwareName?: Maybe<Scalars['String']>;
  subscriptionAmt?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  implementationDate: Scalars['DateTime'];
  noOfUser?: Maybe<Scalars['Float']>;
  saleOrderDate?: Maybe<Scalars['DateTime']>;
  salesOrderNo?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  entityControl?: Maybe<EntityControlInput>;
  packages: Array<Scalars['String']>;
  terminationReason?: Maybe<Scalars['String']>;
  isTerminated?: Maybe<Scalars['Boolean']>;
  commonStatus?: Maybe<CommonStatus>;
  is2FA?: Maybe<Scalars['Boolean']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type TermsConditionsEntity = AuditEntity & {
  __typename?: 'TermsConditionsEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  name: Scalars['String'];
  softwareID: Scalars['String'];
  accountID?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  account: AccountEntity;
};

export type TermsConditionsInput = {
  name: Scalars['String'];
  softwareID: Scalars['String'];
  accountID?: Maybe<Scalars['String']>;
  description: Scalars['String'];
};

export type TotpSecretTyes = {
  __typename?: 'TotpSecretTyes';
  is2FA?: Maybe<Scalars['Boolean']>;
  totpSecret?: Maybe<Scalars['String']>;
  dataURL?: Maybe<Scalars['String']>;
  otpURL?: Maybe<Scalars['String']>;
};

export type UpdateAuditInput = {
  modBy?: Maybe<Scalars['String']>;
};


export type UserActionLogEntity = {
  __typename?: 'UserActionLogEntity';
  ID: Scalars['String'];
  softwareID?: Maybe<Scalars['String']>;
  softwareName?: Maybe<Scalars['String']>;
  packageName?: Maybe<Scalars['String']>;
  entityName?: Maybe<Scalars['String']>;
  userID?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  accountName?: Maybe<Scalars['String']>;
  actionID?: Maybe<Scalars['String']>;
  actionName?: Maybe<Scalars['String']>;
  ipAddress?: Maybe<Scalars['String']>;
  loginDateTime?: Maybe<Scalars['DateTime']>;
};

export type UserActionLogInput = {
  softwareID?: Maybe<Scalars['String']>;
  softwareName?: Maybe<Scalars['String']>;
  packageName?: Maybe<Scalars['String']>;
  entityName?: Maybe<Scalars['String']>;
  userID?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  accountName?: Maybe<Scalars['String']>;
  actionID?: Maybe<Scalars['String']>;
  actionName?: Maybe<Scalars['String']>;
  ipAddress?: Maybe<Scalars['String']>;
};

export type UserEntity = AuditEntity & {
  __typename?: 'UserEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  userName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  contactNo: Scalars['String'];
  is2FA?: Maybe<Scalars['Boolean']>;
  commonStatus: CommonStatus;
  confirmed: Scalars['Boolean'];
  avatar?: Maybe<Scalars['String']>;
  superUser: Scalars['Boolean'];
  accountID: Scalars['String'];
  softwareCode: Scalars['String'];
  loginAttempt: Scalars['Float'];
  blockDuration: Scalars['Float'];
  blockCount: Scalars['Float'];
  loginTs?: Maybe<Scalars['DateTime']>;
  deviceIP?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  address?: Maybe<Address>;
  department?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  lastestAccessedEntity?: Maybe<Scalars['String']>;
};

export type UserInput = {
  ID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  contactNo: Scalars['String'];
  email: Scalars['String'];
  hashed?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  superUser?: Maybe<Scalars['Boolean']>;
  department?: Maybe<Scalars['String']>;
  address?: Maybe<AddressInput>;
  commonStatus?: Maybe<CommonStatus>;
};

export type UserResponse = AuditEntity & {
  __typename?: 'UserResponse';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  name: Scalars['String'];
  userName: Scalars['String'];
  email: Scalars['String'];
  contactNo: Scalars['String'];
  confirmed: Scalars['Boolean'];
  superUser: Scalars['Boolean'];
  accountID: Scalars['String'];
  commonStatus: CommonStatus;
  password?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  lastestAccessedEntity?: Maybe<Scalars['String']>;
  deviceIP?: Maybe<Scalars['String']>;
  loginTs?: Maybe<Scalars['DateTime']>;
  blockDuration?: Maybe<Scalars['Float']>;
  blockCount?: Maybe<Scalars['Float']>;
  loginAttempt?: Maybe<Scalars['Float']>;
  is2FA?: Maybe<Scalars['Boolean']>;
  address?: Maybe<Address>;
  errors?: Maybe<Array<FieldError>>;
};

export type GetAccountQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
}>;


export type GetAccountQuery = (
  { __typename?: 'Query' }
  & { getAccount: Array<(
    { __typename?: 'AccountEntity' }
    & Pick<AccountEntity, 'ID' | 'name' | 'code' | 'contactNo' | 'email' | 'currencyID' | 'regNo' | 'modTs' | 'isInternal'>
    & { currency?: Maybe<(
      { __typename?: 'CurrencyEntity' }
      & Pick<CurrencyEntity, 'ID' | 'name' | 'code'>
    )>, address?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'address' | 'city' | 'state' | 'country' | 'postCode'>
    )>, contactPerson?: Maybe<Array<(
      { __typename?: 'ContactPerson' }
      & Pick<ContactPerson, 'name' | 'designation' | 'contactNo' | 'email'>
    )>>, subscription: Array<(
      { __typename?: 'SubscriptionEntity' }
      & Pick<SubscriptionEntity, 'ID' | 'accountID' | 'subscriptionAmt' | 'noOfUser' | 'salesOrderNo' | 'saleOrderDate' | 'startDate' | 'endDate' | 'implementationDate' | 'modTs' | 'remark' | 'isTerminated' | 'terminationReason' | 'commonStatus' | 'is2FA'>
      & { entityControl?: Maybe<(
        { __typename?: 'EntityControl' }
        & Pick<EntityControl, 'noOfEntity' | 'noofExtraEntity'>
      )>, software: Array<(
        { __typename?: 'SoftwareEntity' }
        & Pick<SoftwareEntity, 'ID' | 'name' | 'code'>
        & { package: Array<(
          { __typename?: 'PackageEntity' }
          & Pick<PackageEntity, 'ID' | 'name'>
        )> }
      )> }
    )> }
  )>, getAllAccount: Array<(
    { __typename?: 'AccountEntity' }
    & Pick<AccountEntity, 'ID' | 'name'>
  )> }
);

export type CreateAccountMutationVariables = Exact<{
  input: AccountInput;
}>;


export type CreateAccountMutation = (
  { __typename?: 'Mutation' }
  & { createAccount: (
    { __typename?: 'AccountEntity' }
    & Pick<AccountEntity, 'ID' | 'name' | 'code' | 'email'>
  ) }
);

export type DeleteAccountMutationVariables = Exact<{
  input: AccountDeleteInput;
}>;


export type DeleteAccountMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteAccount'>
);

export type UpdateAccountMutationVariables = Exact<{
  input: AccountInput;
}>;


export type UpdateAccountMutation = (
  { __typename?: 'Mutation' }
  & { updateAccount: (
    { __typename?: 'AccountEntity' }
    & Pick<AccountEntity, 'ID' | 'name' | 'code'>
  ) }
);

export type GetSubscriptionQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
}>;


export type GetSubscriptionQuery = (
  { __typename?: 'Query' }
  & { getSubscription: Array<(
    { __typename?: 'SubscriptionEntity' }
    & Pick<SubscriptionEntity, 'ID' | 'accountID' | 'subscriptionAmt' | 'noOfUser' | 'salesOrderNo' | 'saleOrderDate' | 'startDate' | 'endDate' | 'implementationDate' | 'modTs' | 'remark' | 'isTerminated' | 'terminationReason' | 'commonStatus' | 'is2FA'>
    & { entityControl?: Maybe<(
      { __typename?: 'EntityControl' }
      & Pick<EntityControl, 'noOfEntity' | 'noofExtraEntity'>
    )>, software: Array<(
      { __typename?: 'SoftwareEntity' }
      & Pick<SoftwareEntity, 'ID' | 'name' | 'code'>
      & { package: Array<(
        { __typename?: 'PackageEntity' }
        & Pick<PackageEntity, 'ID' | 'name'>
      )> }
    )> }
  )> }
);

export type CreateSubscriptionMutationVariables = Exact<{
  input: SubscriptionInput;
}>;


export type CreateSubscriptionMutation = (
  { __typename?: 'Mutation' }
  & { createSubscription: (
    { __typename?: 'SubscriptionEntity' }
    & Pick<SubscriptionEntity, 'ID' | 'accountID'>
  ) }
);

export type UpdateSubscriptionMutationVariables = Exact<{
  input: SubscriptionUpdateInput;
}>;


export type UpdateSubscriptionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateSubscription'>
);

export type UploadAttachmentProductMutationVariables = Exact<{
  file: Scalars['Upload'];
}>;


export type UploadAttachmentProductMutation = (
  { __typename?: 'Mutation' }
  & { UploadAttachmentProduct: (
    { __typename?: 'AzureStorageOutput' }
    & Pick<AzureStorageOutput, 'FileName' | 'Url'>
  ) }
);

export type UploadAttachmentsMutationVariables = Exact<{
  input: AttachmentInput;
}>;


export type UploadAttachmentsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'uploadAttachments'>
);

export type DeleteAttachmentMutationVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  refID?: Maybe<Scalars['String']>;
}>;


export type DeleteAttachmentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteAttachment'>
);

export type AttachmentListingQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  refID?: Maybe<Scalars['String']>;
  refTable?: Maybe<Scalars['String']>;
}>;


export type AttachmentListingQuery = (
  { __typename?: 'Query' }
  & { AttachmentListing?: Maybe<Array<(
    { __typename?: 'AttachmentEntity' }
    & Pick<AttachmentEntity, 'ID' | 'fileName' | 'fileURL' | 'mediaType' | 'createdTs' | 'refID' | 'commonStatus'>
  )>> }
);

export type LoginMutationVariables = Exact<{
  password: Scalars['String'];
  loginId: Scalars['String'];
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login: (
    { __typename?: 'LoginResponse' }
    & Pick<LoginResponse, 'accessToken'>
    & { user: (
      { __typename?: 'UserResponse' }
      & Pick<UserResponse, 'ID' | 'name' | 'userName' | 'email' | 'contactNo' | 'department' | 'jobTitle' | 'commonStatus' | 'confirmed' | 'avatar' | 'superUser' | 'accountID' | 'is2FA'>
      & { address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'address' | 'city' | 'state' | 'country' | 'postCode'>
      )> }
    ) }
  ) }
);

export type LoggedInUserProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type LoggedInUserProfileQuery = (
  { __typename?: 'Query' }
  & { loggedInUserProfile: (
    { __typename?: 'UserEntity' }
    & Pick<UserEntity, 'ID' | 'name' | 'userName' | 'email' | 'contactNo' | 'department' | 'jobTitle' | 'commonStatus' | 'confirmed' | 'avatar' | 'superUser' | 'is2FA' | 'accountID'>
    & { address?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'address' | 'city' | 'state' | 'country' | 'postCode'>
    )> }
  ) }
);

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'logout'>
);

export type ChangePasswordMutationVariables = Exact<{
  newPassword: Scalars['String'];
  currentPassword: Scalars['String'];
}>;


export type ChangePasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'changePassword'>
);

export type ActivateUserMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type ActivateUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'activateUser'>
);

export type ForgotPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ForgotPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'forgotPassword'>
);

export type ResetPasswordMutationVariables = Exact<{
  userID?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  token: Scalars['String'];
}>;


export type ResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'resetPassword'>
);

export type CreatePasswordMutationVariables = Exact<{
  accountID: Scalars['String'];
  userID: Scalars['String'];
  confirmPassword: Scalars['String'];
  password: Scalars['String'];
}>;


export type CreatePasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createPassword'>
);

export type TwoFaTotpSecretToggleMutationVariables = Exact<{
  is2FAEnable: Scalars['Boolean'];
}>;


export type TwoFaTotpSecretToggleMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'TwoFATotpSecretToggle'>
);

export type TwoFaTotpDetailsQueryVariables = Exact<{ [key: string]: never; }>;


export type TwoFaTotpDetailsQuery = (
  { __typename?: 'Query' }
  & { TwoFATotpDetails: (
    { __typename?: 'TotpSecretTyes' }
    & Pick<TotpSecretTyes, 'is2FA' | 'totpSecret' | 'dataURL' | 'otpURL'>
  ) }
);

export type TotpValidateMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type TotpValidateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'totpValidate'>
);

export type GetCurrencyQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetCurrencyQuery = (
  { __typename?: 'Query' }
  & { getCurrency: Array<(
    { __typename?: 'CurrencyEntity' }
    & Pick<CurrencyEntity, 'ID' | 'code' | 'name' | 'symbol'>
  )> }
);

export type UpdateCurrencyMutationVariables = Exact<{
  input: CurrencyInput;
}>;


export type UpdateCurrencyMutation = (
  { __typename?: 'Mutation' }
  & { updateCurrency: (
    { __typename?: 'CurrencyEntity' }
    & Pick<CurrencyEntity, 'ID' | 'code' | 'name' | 'symbol'>
  ) }
);

export type CreateCurrencyMutationVariables = Exact<{
  input: CurrencyInput;
}>;


export type CreateCurrencyMutation = (
  { __typename?: 'Mutation' }
  & { createCurrency: (
    { __typename?: 'CurrencyEntity' }
    & Pick<CurrencyEntity, 'ID' | 'code' | 'name' | 'symbol'>
  ) }
);

export type DeleteCurrencyMutationVariables = Exact<{
  input: CurrencyDeleteInput;
}>;


export type DeleteCurrencyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteCurrency'>
);

export type GetCountryQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
}>;


export type GetCountryQuery = (
  { __typename?: 'Query' }
  & { getCountry: Array<(
    { __typename?: 'CountryEntity' }
    & Pick<CountryEntity, 'name' | 'ID'>
  )> }
);

export type GetStatebyCountryQueryVariables = Exact<{
  countryID: Scalars['String'];
}>;


export type GetStatebyCountryQuery = (
  { __typename?: 'Query' }
  & { getStatebyCountry: Array<(
    { __typename?: 'StateEntity' }
    & Pick<StateEntity, 'name' | 'ID'>
  )> }
);

export type GetCitybyStateQueryVariables = Exact<{
  stateID: Scalars['String'];
}>;


export type GetCitybyStateQuery = (
  { __typename?: 'Query' }
  & { getCitybyState: Array<(
    { __typename?: 'CityEntity' }
    & Pick<CityEntity, 'ID' | 'name'>
  )> }
);

export type GetPostcodebyCityQueryVariables = Exact<{
  cityID: Scalars['String'];
}>;


export type GetPostcodebyCityQuery = (
  { __typename?: 'Query' }
  & { getPostcodebyCity: Array<(
    { __typename?: 'PostcodeEntity' }
    & Pick<PostcodeEntity, 'ID' | 'postcode'>
  )> }
);

export type DeclareSoftwareCodeEnumQueryVariables = Exact<{
  SoftwareCode: SoftwareCode;
}>;


export type DeclareSoftwareCodeEnumQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'declareSoftwareCodeEnum'>
);

export type DeclareSoftwarePackagesEnumQueryVariables = Exact<{
  ContractPackage: ContractPackage;
  HotelPackage: HotelPackage;
  HRPackage: HrPackage;
  IfcaPackage: IfcaPackage;
  MenuPackages: MenuPackages;
  PropertyPackages: PropertyPackages;
  ReportDesignerPackage: ReportDesignerPackage;
  AccountPackage: AccountPackage;
}>;


export type DeclareSoftwarePackagesEnumQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'declareSoftwarePackagesEnum'>
);

export type GetAccountsBySoftwareCodeQueryVariables = Exact<{
  softwareCode: Scalars['String'];
}>;


export type GetAccountsBySoftwareCodeQuery = (
  { __typename?: 'Query' }
  & { getAccountsBySoftwareCode: Array<(
    { __typename?: 'AccountEntity' }
    & Pick<AccountEntity, 'ID' | 'name' | 'code' | 'isInternal' | 'latestSubscription'>
  )> }
);

export type GetUserQueryVariables = Exact<{
  ID: Scalars['String'];
}>;


export type GetUserQuery = (
  { __typename?: 'Query' }
  & { getUser: (
    { __typename?: 'UserEntity' }
    & Pick<UserEntity, 'ID' | 'name' | 'department' | 'email' | 'jobTitle' | 'contactNo' | 'superUser' | 'commonStatus' | 'userName'>
  ) }
);

export type GetUsersByAccountQueryVariables = Exact<{
  accountID: Scalars['String'];
  status?: Maybe<CommonStatus>;
}>;


export type GetUsersByAccountQuery = (
  { __typename?: 'Query' }
  & { getUsersByAccount: Array<(
    { __typename?: 'UserEntity' }
    & Pick<UserEntity, 'ID' | 'userName' | 'email' | 'contactNo' | 'commonStatus' | 'confirmed' | 'avatar' | 'superUser' | 'accountID' | 'softwareCode' | 'name' | 'department' | 'jobTitle'>
  )> }
);

export type CreateUserMutationVariables = Exact<{
  input: UserInput;
}>;


export type CreateUserMutation = (
  { __typename?: 'Mutation' }
  & { createUser: (
    { __typename?: 'UserEntity' }
    & Pick<UserEntity, 'ID' | 'email'>
  ) }
);

export type UpdateUserMutationVariables = Exact<{
  input: UserInput;
}>;


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { updateUser: (
    { __typename?: 'UserEntity' }
    & Pick<UserEntity, 'ID'>
  ) }
);


export const GetAccountDocument = gql`
    query getAccount($ID: String) {
  getAccount(ID: $ID) {
    ID
    name
    code
    contactNo
    email
    currencyID
    currency {
      ID
      name
      code
    }
    regNo
    modTs
    isInternal
    address {
      address
      city
      state
      country
      postCode
    }
    contactPerson {
      name
      designation
      contactNo
      email
    }
    subscription {
      ID
      accountID
      subscriptionAmt
      noOfUser
      salesOrderNo
      saleOrderDate
      startDate
      endDate
      implementationDate
      modTs
      remark
      isTerminated
      terminationReason
      commonStatus
      is2FA
      entityControl {
        noOfEntity
        noofExtraEntity
      }
      software {
        ID
        name
        code
        package {
          ID
          name
        }
      }
    }
  }
  getAllAccount: getAccount {
    ID
    name
  }
}
    `;

/**
 * __useGetAccountQuery__
 *
 * To run a query within a React component, call `useGetAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useGetAccountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAccountQuery, GetAccountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAccountQuery, GetAccountQueryVariables>(GetAccountDocument, baseOptions);
      }
export function useGetAccountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAccountQuery, GetAccountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAccountQuery, GetAccountQueryVariables>(GetAccountDocument, baseOptions);
        }
export type GetAccountQueryHookResult = ReturnType<typeof useGetAccountQuery>;
export type GetAccountLazyQueryHookResult = ReturnType<typeof useGetAccountLazyQuery>;
export type GetAccountQueryResult = ApolloReactCommon.QueryResult<GetAccountQuery, GetAccountQueryVariables>;
export const CreateAccountDocument = gql`
    mutation createAccount($input: AccountInput!) {
  createAccount(input: $input) {
    ID
    name
    code
    email
  }
}
    `;
export type CreateAccountMutationFn = ApolloReactCommon.MutationFunction<CreateAccountMutation, CreateAccountMutationVariables>;

/**
 * __useCreateAccountMutation__
 *
 * To run a mutation, you first call `useCreateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccountMutation, { data, loading, error }] = useCreateAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAccountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateAccountMutation, CreateAccountMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateAccountMutation, CreateAccountMutationVariables>(CreateAccountDocument, baseOptions);
      }
export type CreateAccountMutationHookResult = ReturnType<typeof useCreateAccountMutation>;
export type CreateAccountMutationResult = ApolloReactCommon.MutationResult<CreateAccountMutation>;
export type CreateAccountMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateAccountMutation, CreateAccountMutationVariables>;
export const DeleteAccountDocument = gql`
    mutation deleteAccount($input: AccountDeleteInput!) {
  deleteAccount(input: $input)
}
    `;
export type DeleteAccountMutationFn = ApolloReactCommon.MutationFunction<DeleteAccountMutation, DeleteAccountMutationVariables>;

/**
 * __useDeleteAccountMutation__
 *
 * To run a mutation, you first call `useDeleteAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAccountMutation, { data, loading, error }] = useDeleteAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteAccountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteAccountMutation, DeleteAccountMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteAccountMutation, DeleteAccountMutationVariables>(DeleteAccountDocument, baseOptions);
      }
export type DeleteAccountMutationHookResult = ReturnType<typeof useDeleteAccountMutation>;
export type DeleteAccountMutationResult = ApolloReactCommon.MutationResult<DeleteAccountMutation>;
export type DeleteAccountMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteAccountMutation, DeleteAccountMutationVariables>;
export const UpdateAccountDocument = gql`
    mutation updateAccount($input: AccountInput!) {
  updateAccount(input: $input) {
    ID
    name
    code
  }
}
    `;
export type UpdateAccountMutationFn = ApolloReactCommon.MutationFunction<UpdateAccountMutation, UpdateAccountMutationVariables>;

/**
 * __useUpdateAccountMutation__
 *
 * To run a mutation, you first call `useUpdateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountMutation, { data, loading, error }] = useUpdateAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAccountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateAccountMutation, UpdateAccountMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateAccountMutation, UpdateAccountMutationVariables>(UpdateAccountDocument, baseOptions);
      }
export type UpdateAccountMutationHookResult = ReturnType<typeof useUpdateAccountMutation>;
export type UpdateAccountMutationResult = ApolloReactCommon.MutationResult<UpdateAccountMutation>;
export type UpdateAccountMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateAccountMutation, UpdateAccountMutationVariables>;
export const GetSubscriptionDocument = gql`
    query getSubscription($ID: String, $accountID: String, $orderByDesc: String) {
  getSubscription(ID: $ID, accountID: $accountID, orderByDesc: $orderByDesc) {
    ID
    accountID
    subscriptionAmt
    noOfUser
    salesOrderNo
    saleOrderDate
    startDate
    endDate
    implementationDate
    modTs
    remark
    isTerminated
    terminationReason
    commonStatus
    is2FA
    entityControl {
      noOfEntity
      noofExtraEntity
    }
    software {
      ID
      name
      code
      package {
        ID
        name
      }
    }
  }
}
    `;

/**
 * __useGetSubscriptionQuery__
 *
 * To run a query within a React component, call `useGetSubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscriptionQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      accountID: // value for 'accountID'
 *      orderByDesc: // value for 'orderByDesc'
 *   },
 * });
 */
export function useGetSubscriptionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubscriptionQuery, GetSubscriptionQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubscriptionQuery, GetSubscriptionQueryVariables>(GetSubscriptionDocument, baseOptions);
      }
export function useGetSubscriptionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubscriptionQuery, GetSubscriptionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubscriptionQuery, GetSubscriptionQueryVariables>(GetSubscriptionDocument, baseOptions);
        }
export type GetSubscriptionQueryHookResult = ReturnType<typeof useGetSubscriptionQuery>;
export type GetSubscriptionLazyQueryHookResult = ReturnType<typeof useGetSubscriptionLazyQuery>;
export type GetSubscriptionQueryResult = ApolloReactCommon.QueryResult<GetSubscriptionQuery, GetSubscriptionQueryVariables>;
export const CreateSubscriptionDocument = gql`
    mutation createSubscription($input: SubscriptionInput!) {
  createSubscription(input: $input) {
    ID
    accountID
  }
}
    `;
export type CreateSubscriptionMutationFn = ApolloReactCommon.MutationFunction<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>;

/**
 * __useCreateSubscriptionMutation__
 *
 * To run a mutation, you first call `useCreateSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubscriptionMutation, { data, loading, error }] = useCreateSubscriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSubscriptionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>(CreateSubscriptionDocument, baseOptions);
      }
export type CreateSubscriptionMutationHookResult = ReturnType<typeof useCreateSubscriptionMutation>;
export type CreateSubscriptionMutationResult = ApolloReactCommon.MutationResult<CreateSubscriptionMutation>;
export type CreateSubscriptionMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>;
export const UpdateSubscriptionDocument = gql`
    mutation updateSubscription($input: SubscriptionUpdateInput!) {
  updateSubscription(input: $input)
}
    `;
export type UpdateSubscriptionMutationFn = ApolloReactCommon.MutationFunction<UpdateSubscriptionMutation, UpdateSubscriptionMutationVariables>;

/**
 * __useUpdateSubscriptionMutation__
 *
 * To run a mutation, you first call `useUpdateSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubscriptionMutation, { data, loading, error }] = useUpdateSubscriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSubscriptionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSubscriptionMutation, UpdateSubscriptionMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSubscriptionMutation, UpdateSubscriptionMutationVariables>(UpdateSubscriptionDocument, baseOptions);
      }
export type UpdateSubscriptionMutationHookResult = ReturnType<typeof useUpdateSubscriptionMutation>;
export type UpdateSubscriptionMutationResult = ApolloReactCommon.MutationResult<UpdateSubscriptionMutation>;
export type UpdateSubscriptionMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSubscriptionMutation, UpdateSubscriptionMutationVariables>;
export const UploadAttachmentProductDocument = gql`
    mutation UploadAttachmentProduct($file: Upload!) {
  UploadAttachmentProduct(file: $file) {
    FileName
    Url
  }
}
    `;
export type UploadAttachmentProductMutationFn = ApolloReactCommon.MutationFunction<UploadAttachmentProductMutation, UploadAttachmentProductMutationVariables>;

/**
 * __useUploadAttachmentProductMutation__
 *
 * To run a mutation, you first call `useUploadAttachmentProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadAttachmentProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadAttachmentProductMutation, { data, loading, error }] = useUploadAttachmentProductMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadAttachmentProductMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UploadAttachmentProductMutation, UploadAttachmentProductMutationVariables>) {
        return ApolloReactHooks.useMutation<UploadAttachmentProductMutation, UploadAttachmentProductMutationVariables>(UploadAttachmentProductDocument, baseOptions);
      }
export type UploadAttachmentProductMutationHookResult = ReturnType<typeof useUploadAttachmentProductMutation>;
export type UploadAttachmentProductMutationResult = ApolloReactCommon.MutationResult<UploadAttachmentProductMutation>;
export type UploadAttachmentProductMutationOptions = ApolloReactCommon.BaseMutationOptions<UploadAttachmentProductMutation, UploadAttachmentProductMutationVariables>;
export const UploadAttachmentsDocument = gql`
    mutation uploadAttachments($input: AttachmentInput!) {
  uploadAttachments(input: $input)
}
    `;
export type UploadAttachmentsMutationFn = ApolloReactCommon.MutationFunction<UploadAttachmentsMutation, UploadAttachmentsMutationVariables>;

/**
 * __useUploadAttachmentsMutation__
 *
 * To run a mutation, you first call `useUploadAttachmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadAttachmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadAttachmentsMutation, { data, loading, error }] = useUploadAttachmentsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadAttachmentsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UploadAttachmentsMutation, UploadAttachmentsMutationVariables>) {
        return ApolloReactHooks.useMutation<UploadAttachmentsMutation, UploadAttachmentsMutationVariables>(UploadAttachmentsDocument, baseOptions);
      }
export type UploadAttachmentsMutationHookResult = ReturnType<typeof useUploadAttachmentsMutation>;
export type UploadAttachmentsMutationResult = ApolloReactCommon.MutationResult<UploadAttachmentsMutation>;
export type UploadAttachmentsMutationOptions = ApolloReactCommon.BaseMutationOptions<UploadAttachmentsMutation, UploadAttachmentsMutationVariables>;
export const DeleteAttachmentDocument = gql`
    mutation deleteAttachment($ID: String, $refID: String) {
  deleteAttachment(ID: $ID, refID: $refID)
}
    `;
export type DeleteAttachmentMutationFn = ApolloReactCommon.MutationFunction<DeleteAttachmentMutation, DeleteAttachmentMutationVariables>;

/**
 * __useDeleteAttachmentMutation__
 *
 * To run a mutation, you first call `useDeleteAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAttachmentMutation, { data, loading, error }] = useDeleteAttachmentMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      refID: // value for 'refID'
 *   },
 * });
 */
export function useDeleteAttachmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteAttachmentMutation, DeleteAttachmentMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteAttachmentMutation, DeleteAttachmentMutationVariables>(DeleteAttachmentDocument, baseOptions);
      }
export type DeleteAttachmentMutationHookResult = ReturnType<typeof useDeleteAttachmentMutation>;
export type DeleteAttachmentMutationResult = ApolloReactCommon.MutationResult<DeleteAttachmentMutation>;
export type DeleteAttachmentMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteAttachmentMutation, DeleteAttachmentMutationVariables>;
export const AttachmentListingDocument = gql`
    query AttachmentListing($ID: String, $refID: String, $refTable: String) {
  AttachmentListing(ID: $ID, refID: $refID, refTable: $refTable) {
    ID
    fileName
    fileURL
    mediaType
    createdTs
    refID
    commonStatus
  }
}
    `;

/**
 * __useAttachmentListingQuery__
 *
 * To run a query within a React component, call `useAttachmentListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttachmentListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttachmentListingQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      refID: // value for 'refID'
 *      refTable: // value for 'refTable'
 *   },
 * });
 */
export function useAttachmentListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AttachmentListingQuery, AttachmentListingQueryVariables>) {
        return ApolloReactHooks.useQuery<AttachmentListingQuery, AttachmentListingQueryVariables>(AttachmentListingDocument, baseOptions);
      }
export function useAttachmentListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AttachmentListingQuery, AttachmentListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AttachmentListingQuery, AttachmentListingQueryVariables>(AttachmentListingDocument, baseOptions);
        }
export type AttachmentListingQueryHookResult = ReturnType<typeof useAttachmentListingQuery>;
export type AttachmentListingLazyQueryHookResult = ReturnType<typeof useAttachmentListingLazyQuery>;
export type AttachmentListingQueryResult = ApolloReactCommon.QueryResult<AttachmentListingQuery, AttachmentListingQueryVariables>;
export const LoginDocument = gql`
    mutation Login($password: String!, $loginId: String!) {
  login(password: $password, loginId: $loginId) {
    accessToken
    user {
      ID
      name
      userName
      email
      contactNo
      address {
        address
        city
        state
        country
        postCode
      }
      department
      jobTitle
      commonStatus
      confirmed
      avatar
      superUser
      accountID
      is2FA
    }
  }
}
    `;
export type LoginMutationFn = ApolloReactCommon.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      password: // value for 'password'
 *      loginId: // value for 'loginId'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        return ApolloReactHooks.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, baseOptions);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = ApolloReactCommon.MutationResult<LoginMutation>;
export type LoginMutationOptions = ApolloReactCommon.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const LoggedInUserProfileDocument = gql`
    query loggedInUserProfile {
  loggedInUserProfile {
    ID
    name
    userName
    email
    contactNo
    address {
      address
      city
      state
      country
      postCode
    }
    department
    jobTitle
    commonStatus
    confirmed
    avatar
    superUser
    is2FA
    accountID
  }
}
    `;

/**
 * __useLoggedInUserProfileQuery__
 *
 * To run a query within a React component, call `useLoggedInUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoggedInUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoggedInUserProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useLoggedInUserProfileQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LoggedInUserProfileQuery, LoggedInUserProfileQueryVariables>) {
        return ApolloReactHooks.useQuery<LoggedInUserProfileQuery, LoggedInUserProfileQueryVariables>(LoggedInUserProfileDocument, baseOptions);
      }
export function useLoggedInUserProfileLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LoggedInUserProfileQuery, LoggedInUserProfileQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LoggedInUserProfileQuery, LoggedInUserProfileQueryVariables>(LoggedInUserProfileDocument, baseOptions);
        }
export type LoggedInUserProfileQueryHookResult = ReturnType<typeof useLoggedInUserProfileQuery>;
export type LoggedInUserProfileLazyQueryHookResult = ReturnType<typeof useLoggedInUserProfileLazyQuery>;
export type LoggedInUserProfileQueryResult = ApolloReactCommon.QueryResult<LoggedInUserProfileQuery, LoggedInUserProfileQueryVariables>;
export const LogoutDocument = gql`
    mutation logout {
  logout
}
    `;
export type LogoutMutationFn = ApolloReactCommon.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        return ApolloReactHooks.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, baseOptions);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = ApolloReactCommon.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = ApolloReactCommon.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const ChangePasswordDocument = gql`
    mutation changePassword($newPassword: String!, $currentPassword: String!) {
  changePassword(newPassword: $newPassword, currentPassword: $currentPassword)
}
    `;
export type ChangePasswordMutationFn = ApolloReactCommon.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      newPassword: // value for 'newPassword'
 *      currentPassword: // value for 'currentPassword'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, baseOptions);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = ApolloReactCommon.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const ActivateUserDocument = gql`
    mutation activateUser($token: String!) {
  activateUser(token: $token)
}
    `;
export type ActivateUserMutationFn = ApolloReactCommon.MutationFunction<ActivateUserMutation, ActivateUserMutationVariables>;

/**
 * __useActivateUserMutation__
 *
 * To run a mutation, you first call `useActivateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateUserMutation, { data, loading, error }] = useActivateUserMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useActivateUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ActivateUserMutation, ActivateUserMutationVariables>) {
        return ApolloReactHooks.useMutation<ActivateUserMutation, ActivateUserMutationVariables>(ActivateUserDocument, baseOptions);
      }
export type ActivateUserMutationHookResult = ReturnType<typeof useActivateUserMutation>;
export type ActivateUserMutationResult = ApolloReactCommon.MutationResult<ActivateUserMutation>;
export type ActivateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<ActivateUserMutation, ActivateUserMutationVariables>;
export const ForgotPasswordDocument = gql`
    mutation forgotPassword($email: String!) {
  forgotPassword(email: $email)
}
    `;
export type ForgotPasswordMutationFn = ApolloReactCommon.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useForgotPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, baseOptions);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = ApolloReactCommon.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($userID: String, $password: String!, $token: String!) {
  resetPassword(userID: $userID, password: $password, token: $token)
}
    `;
export type ResetPasswordMutationFn = ApolloReactCommon.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      userID: // value for 'userID'
 *      password: // value for 'password'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, baseOptions);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = ApolloReactCommon.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const CreatePasswordDocument = gql`
    mutation createPassword($accountID: String!, $userID: String!, $confirmPassword: String!, $password: String!) {
  createPassword(accountID: $accountID, userID: $userID, confirmPassword: $confirmPassword, password: $password)
}
    `;
export type CreatePasswordMutationFn = ApolloReactCommon.MutationFunction<CreatePasswordMutation, CreatePasswordMutationVariables>;

/**
 * __useCreatePasswordMutation__
 *
 * To run a mutation, you first call `useCreatePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPasswordMutation, { data, loading, error }] = useCreatePasswordMutation({
 *   variables: {
 *      accountID: // value for 'accountID'
 *      userID: // value for 'userID'
 *      confirmPassword: // value for 'confirmPassword'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useCreatePasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePasswordMutation, CreatePasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<CreatePasswordMutation, CreatePasswordMutationVariables>(CreatePasswordDocument, baseOptions);
      }
export type CreatePasswordMutationHookResult = ReturnType<typeof useCreatePasswordMutation>;
export type CreatePasswordMutationResult = ApolloReactCommon.MutationResult<CreatePasswordMutation>;
export type CreatePasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<CreatePasswordMutation, CreatePasswordMutationVariables>;
export const TwoFaTotpSecretToggleDocument = gql`
    mutation TwoFATotpSecretToggle($is2FAEnable: Boolean!) {
  TwoFATotpSecretToggle(is2FAEnable: $is2FAEnable)
}
    `;
export type TwoFaTotpSecretToggleMutationFn = ApolloReactCommon.MutationFunction<TwoFaTotpSecretToggleMutation, TwoFaTotpSecretToggleMutationVariables>;

/**
 * __useTwoFaTotpSecretToggleMutation__
 *
 * To run a mutation, you first call `useTwoFaTotpSecretToggleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTwoFaTotpSecretToggleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [twoFaTotpSecretToggleMutation, { data, loading, error }] = useTwoFaTotpSecretToggleMutation({
 *   variables: {
 *      is2FAEnable: // value for 'is2FAEnable'
 *   },
 * });
 */
export function useTwoFaTotpSecretToggleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TwoFaTotpSecretToggleMutation, TwoFaTotpSecretToggleMutationVariables>) {
        return ApolloReactHooks.useMutation<TwoFaTotpSecretToggleMutation, TwoFaTotpSecretToggleMutationVariables>(TwoFaTotpSecretToggleDocument, baseOptions);
      }
export type TwoFaTotpSecretToggleMutationHookResult = ReturnType<typeof useTwoFaTotpSecretToggleMutation>;
export type TwoFaTotpSecretToggleMutationResult = ApolloReactCommon.MutationResult<TwoFaTotpSecretToggleMutation>;
export type TwoFaTotpSecretToggleMutationOptions = ApolloReactCommon.BaseMutationOptions<TwoFaTotpSecretToggleMutation, TwoFaTotpSecretToggleMutationVariables>;
export const TwoFaTotpDetailsDocument = gql`
    query TwoFATotpDetails {
  TwoFATotpDetails {
    is2FA
    totpSecret
    dataURL
    otpURL
  }
}
    `;

/**
 * __useTwoFaTotpDetailsQuery__
 *
 * To run a query within a React component, call `useTwoFaTotpDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTwoFaTotpDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTwoFaTotpDetailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTwoFaTotpDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TwoFaTotpDetailsQuery, TwoFaTotpDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<TwoFaTotpDetailsQuery, TwoFaTotpDetailsQueryVariables>(TwoFaTotpDetailsDocument, baseOptions);
      }
export function useTwoFaTotpDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TwoFaTotpDetailsQuery, TwoFaTotpDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TwoFaTotpDetailsQuery, TwoFaTotpDetailsQueryVariables>(TwoFaTotpDetailsDocument, baseOptions);
        }
export type TwoFaTotpDetailsQueryHookResult = ReturnType<typeof useTwoFaTotpDetailsQuery>;
export type TwoFaTotpDetailsLazyQueryHookResult = ReturnType<typeof useTwoFaTotpDetailsLazyQuery>;
export type TwoFaTotpDetailsQueryResult = ApolloReactCommon.QueryResult<TwoFaTotpDetailsQuery, TwoFaTotpDetailsQueryVariables>;
export const TotpValidateDocument = gql`
    mutation totpValidate($token: String!) {
  totpValidate(token: $token)
}
    `;
export type TotpValidateMutationFn = ApolloReactCommon.MutationFunction<TotpValidateMutation, TotpValidateMutationVariables>;

/**
 * __useTotpValidateMutation__
 *
 * To run a mutation, you first call `useTotpValidateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTotpValidateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [totpValidateMutation, { data, loading, error }] = useTotpValidateMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useTotpValidateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TotpValidateMutation, TotpValidateMutationVariables>) {
        return ApolloReactHooks.useMutation<TotpValidateMutation, TotpValidateMutationVariables>(TotpValidateDocument, baseOptions);
      }
export type TotpValidateMutationHookResult = ReturnType<typeof useTotpValidateMutation>;
export type TotpValidateMutationResult = ApolloReactCommon.MutationResult<TotpValidateMutation>;
export type TotpValidateMutationOptions = ApolloReactCommon.BaseMutationOptions<TotpValidateMutation, TotpValidateMutationVariables>;
export const GetCurrencyDocument = gql`
    query getCurrency($ID: String, $orderByAsc: String) {
  getCurrency(ID: $ID, orderByAsc: $orderByAsc) {
    ID
    code
    name
    symbol
  }
}
    `;

/**
 * __useGetCurrencyQuery__
 *
 * To run a query within a React component, call `useGetCurrencyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrencyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrencyQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetCurrencyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCurrencyQuery, GetCurrencyQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCurrencyQuery, GetCurrencyQueryVariables>(GetCurrencyDocument, baseOptions);
      }
export function useGetCurrencyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCurrencyQuery, GetCurrencyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCurrencyQuery, GetCurrencyQueryVariables>(GetCurrencyDocument, baseOptions);
        }
export type GetCurrencyQueryHookResult = ReturnType<typeof useGetCurrencyQuery>;
export type GetCurrencyLazyQueryHookResult = ReturnType<typeof useGetCurrencyLazyQuery>;
export type GetCurrencyQueryResult = ApolloReactCommon.QueryResult<GetCurrencyQuery, GetCurrencyQueryVariables>;
export const UpdateCurrencyDocument = gql`
    mutation updateCurrency($input: CurrencyInput!) {
  updateCurrency(input: $input) {
    ID
    code
    name
    symbol
  }
}
    `;
export type UpdateCurrencyMutationFn = ApolloReactCommon.MutationFunction<UpdateCurrencyMutation, UpdateCurrencyMutationVariables>;

/**
 * __useUpdateCurrencyMutation__
 *
 * To run a mutation, you first call `useUpdateCurrencyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCurrencyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCurrencyMutation, { data, loading, error }] = useUpdateCurrencyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCurrencyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCurrencyMutation, UpdateCurrencyMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCurrencyMutation, UpdateCurrencyMutationVariables>(UpdateCurrencyDocument, baseOptions);
      }
export type UpdateCurrencyMutationHookResult = ReturnType<typeof useUpdateCurrencyMutation>;
export type UpdateCurrencyMutationResult = ApolloReactCommon.MutationResult<UpdateCurrencyMutation>;
export type UpdateCurrencyMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCurrencyMutation, UpdateCurrencyMutationVariables>;
export const CreateCurrencyDocument = gql`
    mutation createCurrency($input: CurrencyInput!) {
  createCurrency(input: $input) {
    ID
    code
    name
    symbol
  }
}
    `;
export type CreateCurrencyMutationFn = ApolloReactCommon.MutationFunction<CreateCurrencyMutation, CreateCurrencyMutationVariables>;

/**
 * __useCreateCurrencyMutation__
 *
 * To run a mutation, you first call `useCreateCurrencyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCurrencyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCurrencyMutation, { data, loading, error }] = useCreateCurrencyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCurrencyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCurrencyMutation, CreateCurrencyMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCurrencyMutation, CreateCurrencyMutationVariables>(CreateCurrencyDocument, baseOptions);
      }
export type CreateCurrencyMutationHookResult = ReturnType<typeof useCreateCurrencyMutation>;
export type CreateCurrencyMutationResult = ApolloReactCommon.MutationResult<CreateCurrencyMutation>;
export type CreateCurrencyMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCurrencyMutation, CreateCurrencyMutationVariables>;
export const DeleteCurrencyDocument = gql`
    mutation deleteCurrency($input: CurrencyDeleteInput!) {
  deleteCurrency(input: $input)
}
    `;
export type DeleteCurrencyMutationFn = ApolloReactCommon.MutationFunction<DeleteCurrencyMutation, DeleteCurrencyMutationVariables>;

/**
 * __useDeleteCurrencyMutation__
 *
 * To run a mutation, you first call `useDeleteCurrencyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCurrencyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCurrencyMutation, { data, loading, error }] = useDeleteCurrencyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCurrencyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteCurrencyMutation, DeleteCurrencyMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteCurrencyMutation, DeleteCurrencyMutationVariables>(DeleteCurrencyDocument, baseOptions);
      }
export type DeleteCurrencyMutationHookResult = ReturnType<typeof useDeleteCurrencyMutation>;
export type DeleteCurrencyMutationResult = ApolloReactCommon.MutationResult<DeleteCurrencyMutation>;
export type DeleteCurrencyMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteCurrencyMutation, DeleteCurrencyMutationVariables>;
export const GetCountryDocument = gql`
    query getCountry($ID: String) {
  getCountry(ID: $ID) {
    name
    ID
  }
}
    `;

/**
 * __useGetCountryQuery__
 *
 * To run a query within a React component, call `useGetCountryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountryQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useGetCountryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCountryQuery, GetCountryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCountryQuery, GetCountryQueryVariables>(GetCountryDocument, baseOptions);
      }
export function useGetCountryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCountryQuery, GetCountryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCountryQuery, GetCountryQueryVariables>(GetCountryDocument, baseOptions);
        }
export type GetCountryQueryHookResult = ReturnType<typeof useGetCountryQuery>;
export type GetCountryLazyQueryHookResult = ReturnType<typeof useGetCountryLazyQuery>;
export type GetCountryQueryResult = ApolloReactCommon.QueryResult<GetCountryQuery, GetCountryQueryVariables>;
export const GetStatebyCountryDocument = gql`
    query getStatebyCountry($countryID: String!) {
  getStatebyCountry(countryID: $countryID) {
    name
    ID
  }
}
    `;

/**
 * __useGetStatebyCountryQuery__
 *
 * To run a query within a React component, call `useGetStatebyCountryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStatebyCountryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStatebyCountryQuery({
 *   variables: {
 *      countryID: // value for 'countryID'
 *   },
 * });
 */
export function useGetStatebyCountryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetStatebyCountryQuery, GetStatebyCountryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetStatebyCountryQuery, GetStatebyCountryQueryVariables>(GetStatebyCountryDocument, baseOptions);
      }
export function useGetStatebyCountryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStatebyCountryQuery, GetStatebyCountryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetStatebyCountryQuery, GetStatebyCountryQueryVariables>(GetStatebyCountryDocument, baseOptions);
        }
export type GetStatebyCountryQueryHookResult = ReturnType<typeof useGetStatebyCountryQuery>;
export type GetStatebyCountryLazyQueryHookResult = ReturnType<typeof useGetStatebyCountryLazyQuery>;
export type GetStatebyCountryQueryResult = ApolloReactCommon.QueryResult<GetStatebyCountryQuery, GetStatebyCountryQueryVariables>;
export const GetCitybyStateDocument = gql`
    query getCitybyState($stateID: String!) {
  getCitybyState(stateID: $stateID) {
    ID
    name
  }
}
    `;

/**
 * __useGetCitybyStateQuery__
 *
 * To run a query within a React component, call `useGetCitybyStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCitybyStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCitybyStateQuery({
 *   variables: {
 *      stateID: // value for 'stateID'
 *   },
 * });
 */
export function useGetCitybyStateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCitybyStateQuery, GetCitybyStateQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCitybyStateQuery, GetCitybyStateQueryVariables>(GetCitybyStateDocument, baseOptions);
      }
export function useGetCitybyStateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCitybyStateQuery, GetCitybyStateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCitybyStateQuery, GetCitybyStateQueryVariables>(GetCitybyStateDocument, baseOptions);
        }
export type GetCitybyStateQueryHookResult = ReturnType<typeof useGetCitybyStateQuery>;
export type GetCitybyStateLazyQueryHookResult = ReturnType<typeof useGetCitybyStateLazyQuery>;
export type GetCitybyStateQueryResult = ApolloReactCommon.QueryResult<GetCitybyStateQuery, GetCitybyStateQueryVariables>;
export const GetPostcodebyCityDocument = gql`
    query getPostcodebyCity($cityID: String!) {
  getPostcodebyCity(cityID: $cityID) {
    ID
    postcode
  }
}
    `;

/**
 * __useGetPostcodebyCityQuery__
 *
 * To run a query within a React component, call `useGetPostcodebyCityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPostcodebyCityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPostcodebyCityQuery({
 *   variables: {
 *      cityID: // value for 'cityID'
 *   },
 * });
 */
export function useGetPostcodebyCityQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPostcodebyCityQuery, GetPostcodebyCityQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPostcodebyCityQuery, GetPostcodebyCityQueryVariables>(GetPostcodebyCityDocument, baseOptions);
      }
export function useGetPostcodebyCityLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPostcodebyCityQuery, GetPostcodebyCityQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPostcodebyCityQuery, GetPostcodebyCityQueryVariables>(GetPostcodebyCityDocument, baseOptions);
        }
export type GetPostcodebyCityQueryHookResult = ReturnType<typeof useGetPostcodebyCityQuery>;
export type GetPostcodebyCityLazyQueryHookResult = ReturnType<typeof useGetPostcodebyCityLazyQuery>;
export type GetPostcodebyCityQueryResult = ApolloReactCommon.QueryResult<GetPostcodebyCityQuery, GetPostcodebyCityQueryVariables>;
export const DeclareSoftwareCodeEnumDocument = gql`
    query declareSoftwareCodeEnum($SoftwareCode: SoftwareCode!) {
  declareSoftwareCodeEnum(SoftwareCode: $SoftwareCode)
}
    `;

/**
 * __useDeclareSoftwareCodeEnumQuery__
 *
 * To run a query within a React component, call `useDeclareSoftwareCodeEnumQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeclareSoftwareCodeEnumQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeclareSoftwareCodeEnumQuery({
 *   variables: {
 *      SoftwareCode: // value for 'SoftwareCode'
 *   },
 * });
 */
export function useDeclareSoftwareCodeEnumQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DeclareSoftwareCodeEnumQuery, DeclareSoftwareCodeEnumQueryVariables>) {
        return ApolloReactHooks.useQuery<DeclareSoftwareCodeEnumQuery, DeclareSoftwareCodeEnumQueryVariables>(DeclareSoftwareCodeEnumDocument, baseOptions);
      }
export function useDeclareSoftwareCodeEnumLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DeclareSoftwareCodeEnumQuery, DeclareSoftwareCodeEnumQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DeclareSoftwareCodeEnumQuery, DeclareSoftwareCodeEnumQueryVariables>(DeclareSoftwareCodeEnumDocument, baseOptions);
        }
export type DeclareSoftwareCodeEnumQueryHookResult = ReturnType<typeof useDeclareSoftwareCodeEnumQuery>;
export type DeclareSoftwareCodeEnumLazyQueryHookResult = ReturnType<typeof useDeclareSoftwareCodeEnumLazyQuery>;
export type DeclareSoftwareCodeEnumQueryResult = ApolloReactCommon.QueryResult<DeclareSoftwareCodeEnumQuery, DeclareSoftwareCodeEnumQueryVariables>;
export const DeclareSoftwarePackagesEnumDocument = gql`
    query declareSoftwarePackagesEnum($ContractPackage: ContractPackage!, $HotelPackage: HotelPackage!, $HRPackage: HRPackage!, $IfcaPackage: IfcaPackage!, $MenuPackages: MenuPackages!, $PropertyPackages: PropertyPackages!, $ReportDesignerPackage: ReportDesignerPackage!, $AccountPackage: AccountPackage!) {
  declareSoftwarePackagesEnum(ContractPackage: $ContractPackage, HotelPackage: $HotelPackage, HRPackage: $HRPackage, IfcaPackage: $IfcaPackage, MenuPackages: $MenuPackages, PropertyPackages: $PropertyPackages, ReportDesignerPackage: $ReportDesignerPackage, AccountPackage: $AccountPackage)
}
    `;

/**
 * __useDeclareSoftwarePackagesEnumQuery__
 *
 * To run a query within a React component, call `useDeclareSoftwarePackagesEnumQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeclareSoftwarePackagesEnumQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeclareSoftwarePackagesEnumQuery({
 *   variables: {
 *      ContractPackage: // value for 'ContractPackage'
 *      HotelPackage: // value for 'HotelPackage'
 *      HRPackage: // value for 'HRPackage'
 *      IfcaPackage: // value for 'IfcaPackage'
 *      MenuPackages: // value for 'MenuPackages'
 *      PropertyPackages: // value for 'PropertyPackages'
 *      ReportDesignerPackage: // value for 'ReportDesignerPackage'
 *      AccountPackage: // value for 'AccountPackage'
 *   },
 * });
 */
export function useDeclareSoftwarePackagesEnumQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DeclareSoftwarePackagesEnumQuery, DeclareSoftwarePackagesEnumQueryVariables>) {
        return ApolloReactHooks.useQuery<DeclareSoftwarePackagesEnumQuery, DeclareSoftwarePackagesEnumQueryVariables>(DeclareSoftwarePackagesEnumDocument, baseOptions);
      }
export function useDeclareSoftwarePackagesEnumLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DeclareSoftwarePackagesEnumQuery, DeclareSoftwarePackagesEnumQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DeclareSoftwarePackagesEnumQuery, DeclareSoftwarePackagesEnumQueryVariables>(DeclareSoftwarePackagesEnumDocument, baseOptions);
        }
export type DeclareSoftwarePackagesEnumQueryHookResult = ReturnType<typeof useDeclareSoftwarePackagesEnumQuery>;
export type DeclareSoftwarePackagesEnumLazyQueryHookResult = ReturnType<typeof useDeclareSoftwarePackagesEnumLazyQuery>;
export type DeclareSoftwarePackagesEnumQueryResult = ApolloReactCommon.QueryResult<DeclareSoftwarePackagesEnumQuery, DeclareSoftwarePackagesEnumQueryVariables>;
export const GetAccountsBySoftwareCodeDocument = gql`
    query getAccountsBySoftwareCode($softwareCode: String!) {
  getAccountsBySoftwareCode(softwareCode: $softwareCode) {
    ID
    name
    code
    isInternal
    latestSubscription(softwareCode: $softwareCode)
  }
}
    `;

/**
 * __useGetAccountsBySoftwareCodeQuery__
 *
 * To run a query within a React component, call `useGetAccountsBySoftwareCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountsBySoftwareCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountsBySoftwareCodeQuery({
 *   variables: {
 *      softwareCode: // value for 'softwareCode'
 *   },
 * });
 */
export function useGetAccountsBySoftwareCodeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAccountsBySoftwareCodeQuery, GetAccountsBySoftwareCodeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAccountsBySoftwareCodeQuery, GetAccountsBySoftwareCodeQueryVariables>(GetAccountsBySoftwareCodeDocument, baseOptions);
      }
export function useGetAccountsBySoftwareCodeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAccountsBySoftwareCodeQuery, GetAccountsBySoftwareCodeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAccountsBySoftwareCodeQuery, GetAccountsBySoftwareCodeQueryVariables>(GetAccountsBySoftwareCodeDocument, baseOptions);
        }
export type GetAccountsBySoftwareCodeQueryHookResult = ReturnType<typeof useGetAccountsBySoftwareCodeQuery>;
export type GetAccountsBySoftwareCodeLazyQueryHookResult = ReturnType<typeof useGetAccountsBySoftwareCodeLazyQuery>;
export type GetAccountsBySoftwareCodeQueryResult = ApolloReactCommon.QueryResult<GetAccountsBySoftwareCodeQuery, GetAccountsBySoftwareCodeQueryVariables>;
export const GetUserDocument = gql`
    query getUser($ID: String!) {
  getUser(ID: $ID) {
    ID
    name
    department
    email
    jobTitle
    contactNo
    superUser
    commonStatus
    userName
  }
}
    `;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, baseOptions);
      }
export function useGetUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, baseOptions);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = ApolloReactCommon.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const GetUsersByAccountDocument = gql`
    query getUsersByAccount($accountID: String!, $status: CommonStatus) {
  getUsersByAccount(accountID: $accountID, status: $status) {
    ID
    userName
    email
    contactNo
    commonStatus
    confirmed
    avatar
    superUser
    accountID
    softwareCode
    name
    department
    jobTitle
  }
}
    `;

/**
 * __useGetUsersByAccountQuery__
 *
 * To run a query within a React component, call `useGetUsersByAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersByAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersByAccountQuery({
 *   variables: {
 *      accountID: // value for 'accountID'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useGetUsersByAccountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUsersByAccountQuery, GetUsersByAccountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUsersByAccountQuery, GetUsersByAccountQueryVariables>(GetUsersByAccountDocument, baseOptions);
      }
export function useGetUsersByAccountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUsersByAccountQuery, GetUsersByAccountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUsersByAccountQuery, GetUsersByAccountQueryVariables>(GetUsersByAccountDocument, baseOptions);
        }
export type GetUsersByAccountQueryHookResult = ReturnType<typeof useGetUsersByAccountQuery>;
export type GetUsersByAccountLazyQueryHookResult = ReturnType<typeof useGetUsersByAccountLazyQuery>;
export type GetUsersByAccountQueryResult = ApolloReactCommon.QueryResult<GetUsersByAccountQuery, GetUsersByAccountQueryVariables>;
export const CreateUserDocument = gql`
    mutation createUser($input: UserInput!) {
  createUser(input: $input) {
    ID
    email
  }
}
    `;
export type CreateUserMutationFn = ApolloReactCommon.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, baseOptions);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = ApolloReactCommon.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const UpdateUserDocument = gql`
    mutation updateUser($input: UserInput!) {
  updateUser(input: $input) {
    ID
  }
}
    `;
export type UpdateUserMutationFn = ApolloReactCommon.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, baseOptions);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = ApolloReactCommon.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;