import React, { lazy } from 'react';
import SubscriptionRoutes from './Subscription/SubscriptionRoutes';

const AccountListing = lazy(() =>
  import('./AccountListing').then(module => ({
    default: module.AccountListing,
  })),
);

const AccountForm = lazy(() =>
  import('./AccountForm').then(module => ({
    default: module.AccountForm,
  })),
);

const AccountSubmenu = lazy(() =>
  import('./AccountSubmenu').then(module => ({
    default: module.AccountSubmenu,
  })),
);

const AccountInfo = lazy(() =>
  import('./AccountInfo').then(module => ({
    default: module.AccountInfo,
  })),
);

const AccountRoutes = [
  ...SubscriptionRoutes,
  {
    props: { exact: true, path: '/' },
    component: <AccountListing />,
  },

  {
    props: { exact: true, path: '/account' },
    component: <AccountListing />,
  },

  {
    props: { exact: true, path: '/account/add' },
    component: <AccountForm mode="add" />,
  },

  {
    props: { exact: true, path: '/account/:accountID/edit' },
    component: <AccountForm mode="edit" />,
  },

  {
    props: { exact: true, path: '/account/:accountID' },
    component: <AccountSubmenu />,
  },
  {
    props: { exact: true, path: '/account/:accountID/info' },
    component: <AccountInfo />,
  },
];

export default AccountRoutes;
