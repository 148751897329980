import { setAccessToken } from 'AccessToken';
import { useLogoutMutation } from 'generated/graphql';
import React from 'react';
import { useHistory } from 'react-router-dom';
export const Logout = () => {
  const history = useHistory();
  const [logout, { client }] = useLogoutMutation();
  React.useEffect(() => {
    async function userLogout() {
      await logout();
      setAccessToken('');
      await client!.resetStore();
    }
    userLogout();
    history.push('/login');
  }, []);
  return <></>;
};
