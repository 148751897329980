import { StandardDialog } from '@ifca-root/react-component/src/components/Dialog/StandardDialog'
import { Button, TextField } from '@material-ui/core'
import Logo from 'assets/images/subscriptionx.png'
import Background from 'assets/images/HR-Background.jpg'
import { AuthLayout } from 'components/AuthLayout/AuthLayout'
import { SystemMsgs } from 'containers/helper/SystemMsg'
import { useForgotPasswordMutation } from 'generated/graphql'
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useHistory } from 'react-router'

interface ForgotProps {
  email: string
}
export const ForgotPassword = props => {
  let history = useHistory()
  const [dialog, setDialog] = useState(false)
  const [ForgotPassword, { loading, error }] = useForgotPasswordMutation({
    onError: error => {
      console.log('error', error)
    },
    onCompleted: data => {
      console.log('data', data)
      setDialog(true)
    },
  })
  const { handleSubmit, register, errors, control } = useForm<ForgotProps>({
    defaultValues: {
      email: '',
    },
    mode: 'onChange',
    // resolver: yupResolver(ForgotPasswordSchema),
  })
  const onSubmit = data => {
    console.log(data)
    ForgotPassword({
      variables: {
        email: data.email,
      },
    })
  }
  return (
    <AuthLayout logo={Logo} image={Background}>
      <span className="page-title">Forgot Password</span>
      <form onSubmit={handleSubmit(onSubmit)} id="submit-form">
        <div className="form-box">
          <Controller
            as={TextField}
            fullWidth
            helperText={errors?.email?.message}
            error={errors?.email ? true : false}
            autoComplete="off"
            name="email"
            label="Email"
            required
            control={control}
            ref={register}
          />
          <span className="forget-link" onClick={() => history.push('/login')}>
            Back to login
          </span>
        </div>
        <Button
          variant="contained"
          color="primary"
          className="login-btn"
          type="submit"
        >
          Send
        </Button>
      </form>
      <StandardDialog
        fullWidth={true}
        open={dialog}
        onClose={() => setDialog(false)}
        sections={{
          header: {
            title: 'Info',
          },
          body: () => <div>{SystemMsgs.forgotPassword()}</div>,
          footer: {
            actions: [
              {
                displayText: 'Ok',
                props: {
                  onClick: () => history.push('/login'),
                  variant: 'contained',
                  color: 'primary',
                },
              },
            ],
          },
        }}
      />
    </AuthLayout>
  )
}
