import AccountRoutes from 'containers/AccountModule/AccountRoutes';
import authenticationRoutes from 'containers/Authentication/AuthenticationRoutes';
import CurrencySetupRoutes from 'containers/GeneralModule/CurrencySetupModule/CurrencyRoutes';
import InformationCentreRoutes from 'containers/InformationCentreModule/InformationCentreRoutes';
import UserRoutes from 'containers/UserModule/User/UserRoutes';
import React from 'react';
import { Redirect } from 'react-router-dom';

const redirectRoute = [
  {
    props: { exact: true, path: '/' },
    component: <Redirect to="/account" />,
  },
];

const errorRoute = [
  {
    // props: { exact: true, path: '/' },
    component: <Redirect to="/authentication/404" />,
  },
];

const routes = [
  ...authenticationRoutes,
  ...AccountRoutes,
  ...CurrencySetupRoutes,
  ...InformationCentreRoutes,
  ...UserRoutes,
  ...redirectRoute,
  ...errorRoute,
];

export default routes;
