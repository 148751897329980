export const SystemMsgs = {
  name: () => {
    return 'Name is required'
  },
  loginName: () => {
    return 'Login Name is required'
  },
  mobileNo: () => {
    return 'Mobile No is required'
  },
  specialInstruction: () => {
    return 'Special instruction is required'
  },
  createNewRecord: () => {
    return 'Record added successfully.'
  },
  updateRecord: () => {
    return 'Record updated  successfully.'
  },
  email: () => {
    return 'Email is required'
  },
  password: () => {
    return 'Password is required'
  },
  forgotPassword: () => {
    return 'Please check your inbox for an email we just sent you with instructions on how to reset your password and log into your account.'
  },
  userName: () => {
    return 'Login Name is required'
  },
  department: () => {
    return 'Department is required'
  },
  designation: () => {
    return 'Designation is required'
  },
  contactNo: () => {
    return 'Contact No is required'
  },
  address: () => {
    return 'Address is required'
  },
  state: () => {
    return 'State is required'
  },
  city: () => {
    return 'City is required'
  },
  postCode: () => {
    return 'Postcode is required'
  },
  country: () => {
    return 'Country is required'
  },
  CompanyName: () => {
    return 'Company Name is required'
  },
  regNo: () => {
    return 'Reg no is required'
  },
  contactValidation: () => {
    return 'Enter a valid phone number with country code (e.g. + 60)'
  },
}
