import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog';
import SnackBarMsg from '@ifca-root/react-component/src/components/SnackBar/SnackBarMsg';
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { setAccessToken } from 'AccessToken';
import Logo from 'assets/images/subscriptionx.png';
import Background from 'assets/images/HR-Background.jpg';
import { AuthLayout } from 'components/AuthLayout/AuthLayout';
import {
  LoggedInUserProfileDocument,
  LoggedInUserProfileQuery,
  useLoginMutation,
  useTotpValidateMutation,
} from 'generated/graphql';
import { encryptMessage } from 'helpers/Crypto/JsEncrypt';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';

interface LoginFormProps {
  email: string;
  password: string;
}

export const Login = () => {
  let history = useHistory();
  const [login, { loading, error }] = useLoginMutation({
    onError: error => {
      error.graphQLErrors.map(({ message }) => {
        console.log('ERROR', message);
        setMessage(message);
        setOpenAlert(true);
      });
    },
    update: (store, { data }) => {
      if (!data) {
        return null;
      }
      store.writeQuery<LoggedInUserProfileQuery>({
        query: LoggedInUserProfileDocument,
        data: {
          __typename: 'Query',
          loggedInUserProfile: data.login.user as any,
        },
      });
    },

    onCompleted: data => {
      if (data && data.login.accessToken) {
        setAccessToken(data.login.accessToken);
      }
      reset();
      if (data.login.user.is2FA) {
        setOpenOTPDialog(true);
      } else {
        history.push('/account');
      }
    },
  });

  const [OTPValidate] = useTotpValidateMutation({
    onCompleted: data => {
      setOpenSnackBar(true);
      if (data?.totpValidate) {
        setSnackBarMessage('TOP verification is successful!');
        setTimeout(() => {
          setOpenOTPDialog(false);
          history.push('/account');
        }, 1000);
      } else {
        setSnackBarMessage('TOP verification is failed!, try again');
      }
      setOTP('');
    },
    onError: err => {
      setOpenSnackBar(true);
      setSnackBarMessage('TOP verification is failed!, try again');
      setOTP('');
    },
  });

  const [values, setValues] = React.useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [message, setMessage] = useState('');
  const [OTP, setOTP] = useState('');
  const [OpenOTPDialog, setOpenOTPDialog] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');

  const handleOpenOTPDialog = (action: string) => {
    if (action == 'ok') {
      OTPValidate({
        variables: {
          token: OTP.trim(),
        },
      });
    } else {
      history.push('/logout');
    }
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleClickShowPassword = () => {
    setValues(!values);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const { handleSubmit, register, errors, control, reset, getValues } =
    useForm<LoginFormProps>({
      defaultValues: {
        email: '',
        password: '',
      },
      mode: 'onChange',
      //resolver: yupResolver(LoginSchema),
    });

  const onSubmit = data => {
    login({
      variables: {
        loginId: data?.email,
        password: encryptMessage(data?.password),
      },
    });
  };

  return (
    <>
      {loading && <Loading />}
      <AuthLayout className="margin-override" logo={Logo} image={Background}>
        <span className="page-title">Login</span>
        <form onSubmit={handleSubmit(onSubmit)} id="submit-form">
          <div className="form-box">
            <Controller
              as={TextField}
              fullWidth
              helperText={errors?.email?.message}
              error={errors?.email ? true : false}
              autoComplete="off"
              name="email"
              label="Email*"
              control={control}
              ref={register}
            />
            <Grid container justify="flex-start">
              <Controller
                as={TextField}
                fullWidth
                helperText={errors?.password?.message}
                error={errors?.password ? true : false}
                autoComplete="off"
                name="password"
                label="Password *"
                type={values ? 'text' : 'password'}
                value={getValues('password')}
                //   onChange={handleChange('password')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Password"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {values ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                control={control}
                ref={register}
              />
            </Grid>

            <div className="other-auth">
              <FormControlLabel
                control={<Checkbox name="checkedB" color="primary" />}
                label="Remember Me"
              />
              <span
                className="forget-link"
                onClick={() => history.push('/forgot-password')}
              >
                Forgot Password?
              </span>
            </div>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              className="login-btn"
            >
              Login
            </Button>
          </div>
        </form>

        <CommonDialog
          fullWidth={true}
          open={openAlert}
          onClose={handleCloseAlert}
          sections={{
            header: {
              title: 'Login Error',
            },
            body: () => <div>{message}</div>,
            footer: {
              actions: [
                {
                  displayText: 'Ok',
                  props: {
                    onClick: () => handleCloseAlert(),
                    variant: 'contained',
                    color: 'primary',
                  },
                },
              ],
            },
          }}
        />

        {/* Dialog for OTP verification */}
        <CommonDialog
          fullWidth={true}
          open={OpenOTPDialog}
          onClose={handleCloseAlert}
          sections={{
            header: {
              title: 'Verify OTP',
            },
            body: () => (
              <>
                <div className="content-wrap full">
                  <div className="desc">
                    Enter OTP from Google Authenticator.
                  </div>
                  <div className="desc">
                    <TextField
                      label="OTP"
                      margin="normal"
                      fullWidth
                      required
                      value={OTP}
                      onChange={e => setOTP(e.target.value)}
                    />
                  </div>
                </div>
              </>
            ),
            footer: {
              actions: [
                {
                  displayText: 'Cancel',
                  props: {
                    onClick: () => handleOpenOTPDialog('cancel'),
                    variant: 'contained',
                    color: 'primary',
                  },
                },
                {
                  displayText: 'Ok',
                  props: {
                    onClick: () => handleOpenOTPDialog('ok'),
                    variant: 'contained',
                    color: 'primary',
                    disabled: !OTP,
                  },
                },
              ],
            },
          }}
        />
        <SnackBarMsg
          open={openSnackBar}
          message={snackBarMessage}
          onClose={() => setOpenSnackBar(false)}
          duration={5000}
        />
      </AuthLayout>
    </>
  );
};
