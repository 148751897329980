import * as yup from 'yup'
import { SystemMsgs } from 'containers/helper/SystemMsg'

export const EmailValidatorPattern = () => {
  return /\S+@\S+\.\S+/
}
export const PhoneNoValidatorPattern = () => {
  return /^[+]6?01\d{8}(?:\d{1})?$/
}
export const LandLineValidatorPattern = () => {
  return /^[+]6?0\d{8}(?:\d{1})?$/
}

export const LoginSchema = yup.object().shape({
  email: yup.string().required(SystemMsgs.email()),
  password: yup.string().required(SystemMsgs.password()),
  // product: yup.string().required(),
})

export const ForgotPasswordSchema = yup.object().shape({
  email: yup
    .string()
    .email()
    .required(SystemMsgs.email()),
  // product: yup.string().required(),
})

export const UserSchema = yup.object().shape({
  name: yup.string().required(SystemMsgs.name()),
  username: yup.string().required(SystemMsgs.loginName()),
  email: yup
    .string()
    .email()
    .required(SystemMsgs.email()),
  contactNo: yup
    .string()
    .required(SystemMsgs.mobileNo())
    .matches(LandLineValidatorPattern(), 'Mobile no is not valid'),
})
export const ChangePasswordSchema = yup.object().shape({
  currentPassword: yup.string().required(),
  newPassword: yup
    .string()
    .required('New password is required')

    .min(8, 'Password must be at least 8 characters')
    .matches(
      /(?=.*[A-Z])/,
      'Password must contain at least one uppercase character'
    )
    .matches(
      /(?=.*[a-z])/,
      'Password must contain at least one lowercase character'
    )
    .matches(/(?=.*\d)/, 'Password must contain at least one number')
    .matches(
      /(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})/,
      'Password must contain at least one symbol'
    ),
  confirmPassword: yup
    .string()
    .required()
    .oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
})

export const ResetPasswordSchema = yup.object().shape({
  newPassword: yup
    .string()
    .required('New password is required')

    .min(8, 'Password must be at least 8 characters')
    .matches(
      /(?=.*[A-Z])/,
      'Password must contain at least one uppercase character'
    )
    .matches(
      /(?=.*[a-z])/,
      'Password must contain at least one lowercase character'
    )
    .matches(/(?=.*\d)/, 'Password must contain at least one number')
    .matches(
      /(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})/,
      'Password must contain at least one symbol'
    ),
  confirmPassword: yup
    .string()
    .required()
    .oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
})
