import { getAccessToken } from 'AccessToken';
import React, { useContext } from 'react';
import { Redirect, Route, useHistory, useRouteMatch } from 'react-router';
import AppContext from '../Store/AppContext';

export const PrivateRoute = ({ children, ...rest }) => {
  //const { globalState, dispatch } = useContext(AppContext as any)
  ///let history = useHistory()
  const AccessToken = getAccessToken();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        AccessToken ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

// export const PrivateRoute = ({ children, ...rest }) => {
//   const AccessToken = getAccessToken()
//   const test = ''
//   let settings = useRouteMatch({
//     path: [
//       '/authentication/404',
//       '/forgot-password',
//       '/user/activate/:token',
//       '/reset-password',
//     ],
//     strict: true,
//     sensitive: true,
//   })
//   return (
//     <Route
//       {...rest}
//       render={({ location }) =>
//         AccessToken ? (
//           children
//         ) : (
//           <Redirect
//             to={{
//               pathname: '/login',
//             }}
//           />
//         )
//       }
//     />
//   )
// }
