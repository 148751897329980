import React, { lazy } from 'react'
const UserForm = lazy(() =>
  import('./UserForm').then(module => ({
    default: module.UserForm,
  }))
)
const UserDetail = lazy(() =>
  import('./UserDetail').then(module => ({
    default: module.UserDetail,
  }))
)

const UserListing = lazy(() =>
  import('./UserListing').then(module => ({
    default: module.UserListing,
  }))
)

const UserRoutes = [
  {
    props: { exact: true, path: '/users' },
    component: <UserListing />,
  },
  {
    props: { exact: true, path: '/user/add' },
    component: <UserForm formMode="add" />,
  },
  {
    props: { exact: true, path: '/user/edit' },
    component: <UserForm formMode="edit" />,
  },
  {
    props: { exact: true, path: '/user/detail' },
    component: <UserDetail />,
  },
]

export default UserRoutes
