import AppBar from '@material-ui/core/AppBar'
import IconButton from '@material-ui/core/IconButton'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import MenuIcon from '@material-ui/icons/Menu'
import LogoB from 'assets/images/subscriptionx.png'
import React from 'react'

const useStyles = makeStyles((theme: Theme) => createStyles({}))
interface IHeader {
  onDrawerOpen: any
}
const Header = (props: IHeader) => {
  const { onDrawerOpen, ...rest } = props
  const classes = useStyles()
  return (
    <AppBar {...rest} position="fixed" className="desktop-header">
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={onDrawerOpen}
        >
          <MenuIcon />
        </IconButton>
        <Typography component="div" noWrap>
          <img src={LogoB} alt="SubscriptionX" width={150} />
        </Typography>
        {/* <span className="title">IFCA Template</span> */}
      </Toolbar>
    </AppBar>
  )
}
export default Header
