import React, { lazy } from 'react';

const InformationCentreSubmenu = lazy(() =>
  import('./InformationCentreSubmenu').then(module => ({
    default: module.InformationCentreSubmenu,
  })),
);
const InformationCentreListing = lazy(() =>
  import('./InformationCentreListing').then(module => ({
    default: module.InformationCentreListing,
  })),
);

const InformationCentreRoutes = [
  {
    props: { exact: true, path: '/information-centre/:softwareCode' },
    component: <InformationCentreListing />,
  },
  {
    props: { exact: true, path: '/information-centre' },
    component: <InformationCentreSubmenu />,
  },
];

export default InformationCentreRoutes;
